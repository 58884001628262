<template>
    <BaseSection
        width="content-sm"
        :color-theme="colorTheme"
    >
        <template
            v-if="sectionTitle"
            #title
        >
            {{ sectionTitle }}
        </template>

        <template #default>
            <FormOrderGift v-bind="props">
                <template #formTitle>
                    {{ giftFormTitle }}
                </template>

                <template #termsAndConditionsLabel>
                    {{ giftTermsAndConditionsLabel }}
                </template>
            </FormOrderGift>
        </template>
    </BaseSection>
</template>

<script setup lang="ts">
import type { FlexibleOrderGiftFragment } from '~/@types/craft-schema';

const props = defineProps<FlexibleOrderGiftFragment>();
</script>
