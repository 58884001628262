import { nl } from 'date-fns/locale';
import {
    setDefaultOptions
} from 'date-fns';

setDefaultOptions({ locale: nl, weekStartsOn: 1 });

export const timeFormat = 'HH:mm';

export default defineNuxtPlugin(() => {

});
