<template>
    <div class="form-field-columns">
        <slot />
    </div>
</template>

<script>
export default {
};
</script>

<style lang="less" src="./FormFieldColumns.less" />
