<template>
    <CopyShareButton
        :url="shareUrl"
        class="copy-share-input"
    >
        <div class="copy-share-input__link">
            {{ shareUrl }}
        </div>

        <BaseButton
            element="div"
            class="copy-share-input__button button--secondary"
        >
            Kopieer
        </BaseButton>
    </CopyShareButton>
</template>

<script>
import {
    CopyShareButton
} from '@digitalnatives/share-button';

export default {
    components: {
        CopyShareButton
    },

    props: {
        shareUrl: {
            type: String,
            default: null
        }
    }
};
</script>

<style src="./CopyShareInput.less" lang="less"></style>
