<template>
    <BaseSection
        width="bleed"
        :color-theme="colorTheme"
        data-spacing-lg
    >
        <template
            v-if="sectionTitle"
            #title
        >
            {{ sectionTitle }}
        </template>

        <template #default>
            <QueryCollections
                v-slot="{ entries }"
                :lazy="lazy"
                :collection-type-id="collectionTypeId"
            >
                <CollectionSlider :collections="entries" />
            </QueryCollections>
        </template>
    </BaseSection>
</template>

<script setup lang="ts">
import type { FlexibleCollectionsBlockFragment } from '~/@types/craft-schema';

interface Props extends FlexibleCollectionsBlockFragment {
    lazy: boolean;
}

const props = withDefaults(
    defineProps<Props>(),
    {
        lazy: true
    }
);

const collectionTypeId = computed(() => {
    if (
        props.collectionType &&
        props.collectionType.length > 0 &&
        props.collectionType[0]?.__typename === 'collectionType_Category'
    ) {
        return props.collectionType[0].id;
    }

    return null;
});
</script>
