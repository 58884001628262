<template>
    <BaseForm
        class="order-gift-step-one"
        @submit="onSubmit"
    >
        <template #title>
            <slot name="title" />
        </template>

        <template #content>
            <slot name="description" />

            <BaseFormField
                :required="true"
            >
                <template #label>
                    Aantal gift cards
                </template>

                <BaseSelect
                    v-model="amountField"
                    placeholder="Kies aantal"
                    :options="giftAmountOptions"
                    :clearable="false"
                    required
                >
                    <template #after>
                        <BaseIcon icon="dropdown" />
                    </template>
                </BaseSelect>
            </BaseFormField>

            <FormFieldColumns
                data-columns-mobile="2"
                data-columns-desktop="2"
            >
                <FieldValidator
                    v-slot="{ field, meta, errors, handleReset }"
                    name="firstName"
                >
                    <BaseFormField
                        :required="true"
                        :error="errors"
                    >
                        <template #label>Voornaam</template>

                        <BaseInput
                            v-bind="field"
                            :validator="meta"
                            @clear="handleReset"
                        />
                    </BaseFormField>
                </FieldValidator>

                <FieldValidator
                    v-slot="{ field, meta, errors, handleReset }"
                    name="lastName"
                >
                    <BaseFormField
                        :required="true"
                        :error="errors"
                    >
                        <template #label>Achternaam</template>

                        <BaseInput
                            v-bind="field"
                            :validator="meta"
                            @clear="handleReset"
                        />
                    </BaseFormField>
                </FieldValidator>
            </FormFieldColumns>

            <FieldValidator
                v-slot="{ field, meta, errors, handleReset }"
                name="email"
            >
                <BaseFormField
                    :required="true"
                    :error="errors"
                >
                    <template #label>Email</template>

                    <BaseInput
                        v-bind="field"
                        :validator="meta"
                        type="email"
                        @clear="handleReset"
                    />
                </BaseFormField>
            </FieldValidator>
        </template>

        <template #actions>
            <BaseButton class="button--secondary">
                Volgende stap
            </BaseButton>
        </template>
    </BaseForm>
</template>

<script setup lang="ts">
import { useForm, Field as FieldValidator } from 'vee-validate';
import { useUserStore } from '~/store/user';

const props = defineProps<{
    giftCardPrice: number
}>();

const emit = defineEmits(['submit']);

const userStore = useUserStore();

const { handleSubmit, defineField } = useForm({
    validationSchema: {
        amount: 'required',
        email: 'required|email',
        firstName: 'required',
        lastName: 'required',
    },
    initialValues: {
        amount: 1,
        email: userStore.user?.email,
        firstName: userStore.user?.first_name,
        lastName: userStore.user?.last_name
    }
});

const [amountField] = defineField('amount');

const giftAmountOptions = computed(() => {
    return [...Array(5).keys()].map((x) => {
        return {
            label: `${x + 1} gift card${x > 0 ? 's' : ''} (€${props.giftCardPrice * (x + 1)})`,
            value: x + 1
        };
    });
});

const onSubmit = handleSubmit((formData) => {
    emit('submit', formData);
});
</script>

<style lang="less" src="./FormOrderGiftStepOne.less">
</style>
