import type { AsyncDataRequestStatus } from '#app';
import type { DocumentNode } from 'graphql';
import { type Ref, ref } from 'vue';
import type {
    Page_Page_Entry,
    Page_ProgramOverview_Entry,
    Collection_Default_Entry,
    ErrorPage_ErrorPage_Entry,
    Ether_SeoData,
    Home_Home_Entry
} from '~/@types/craft-schema';

export interface ComposableProps {
    query: DocumentNode;
    variables?: {
        [key: string]: any;
    } | null;
}

type CraftEntry = Home_Home_Entry
    | Collection_Default_Entry
    | Page_Page_Entry
    | Page_ProgramOverview_Entry
    | ErrorPage_ErrorPage_Entry;

export type CraftResponseObject = {
    entry: Ref<CraftEntry|null>;
    entries: Ref<CraftEntry[]|null>;
    seo: Record<string, any>;
    status: Ref<AsyncDataRequestStatus>;
    statusCode: Ref<number>;
}

export const useCraftQuery = async(
    { data, status, error }: {
        data: Ref<{
            entry?: CraftEntry;
            entries?: CraftEntry[];
        }>,
        status: Ref<AsyncDataRequestStatus>;
        error: Ref<
            {
                statusCode: number;
                statusMessage: string;
            }
            | null
        >;
    }
): Promise<CraftResponseObject> => {
    const entry = ref<CraftEntry|null>(null),
        entries = ref<CraftEntry[]|null>(null),
        seo = ref<Ether_SeoData|null>(null),
        statusCode = ref<number>(0);

    if (data.value) {
        if (data.value.entry) {
            entry.value = data.value.entry as CraftEntry;
            seo.value = await useEntrySeo(entry.value.seo || null);
        } else if (data.value.entries) {
            entries.value = data.value.entries as CraftEntry[];
        } else if (error.value) {
            throw createError({
                statusCode: error.value?.statusCode || -1,
                statusMessage: error.value?.statusMessage || 'Internal server error',
                message: error.value?.toString(),
            });
        } else {
            throw createError({ statusCode: 404 });
        }
    }

    return {
        status,
        statusCode,

        seo,
        entry,
        entries
    };
};
