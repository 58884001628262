<template>
    <BaseSection
        width="content-xl"
        :color-theme="colorTheme"
        class="section--text-image"
    >
        <BaseColumns :is-reversed="imageAlignment === 'left'">
            <template #column-text>
                <RichText
                    v-if="richText"
                    :content="richText"
                />
            </template>

            <template #column-image>
                <BaseImage
                    v-if="singleImage"
                    :src="singleImage.w768"
                    :src-tiny="singleImage.w32"
                    :srcset="`
                        ${singleImage.w320} 320w,
                        ${singleImage.w768} 768w,
                        ${singleImage.w980} 980w,
                        ${singleImage.w1260} 1260w
                    `.trim()"
                    :sizes="`
                        (max-width: 380px) 320px,
                        768px
                    `.trim()"
                    :width="singleImage.width"
                    :height="singleImage.height"
                    :title="singleImage.title"
                    :alt="singleImage.alt"
                    :caption="singleImage.caption"
                    :credits="singleImage.credits"
                />
            </template>
        </BaseColumns>
    </BaseSection>
</template>

<script setup lang="ts">
import type { FlexibleTextImageFragment } from '~/@types/craft-schema';
import type { Media_Asset_Extended } from '~/@types/craft-schema.extended';

const props = defineProps<FlexibleTextImageFragment>();

const singleImage = props.image[0] ? props.image[0] as Media_Asset_Extended : null;
</script>
