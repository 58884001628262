import { defineStore } from 'pinia';

export const useSignUpStore = defineStore('signUp', {
    state: () => ({
        membershipStatus: null as null | string,
    }),

    actions: {
        setMembershipStatus(payload: string) {
            this.membershipStatus = payload;
        }
    }
});
