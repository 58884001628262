import type { LinkField_Link, Links_MatrixField } from '~/@types/craft-schema';
import { HOME_URI } from '~/constants/page-uri';

export const LINK = 'link';
export const NUXT_LINK = 'nuxt_link';

export default function useNavigation() {
    const route = useRoute();

    const mapType = (type?: string|null) => {
            switch (type) {
            case 'email':
            case 'tel':
            case 'url':
                return LINK;
            case 'entry':
            case 'category':
            default:
                return NUXT_LINK;
            }
        },

        mapURI = (link?: Links_MatrixField['entry']|null) => {
            if (!link) {
                return '/';
            }

            const type = mapType(link?.type || 'entry');

            if (type === NUXT_LINK) {
                return fixHomeUri(link?.element?.uri || '');
            }

            return link.url || '/';
        },

        isExternal = (link: LinkField_Link) => {
            return link.target === '_blank';
        },

        fixHomeUri = (uri: string) => {
            if (uri === HOME_URI) {
                return {
                    query: route.query && Object.keys(route.query).length > 0
                        ? route.query
                        : null,
                    path: '/'
                };
            }

            return {
                query: route.query,
                path: `/${uri}`
            };
        };

    return {
        mapType,
        mapURI,
        isExternal,
        fixHomeUri
    };
}
