<template>
    <div
        v-if="typeof giftCardPrice === 'number'"
        class="order-gift"
    >
        <div
            v-if="progress"
            class="order-gift__progress"
        >
            {{ progress }}
        </div>

        <FormOrderGiftStepOne
            v-show="currentStep === FORM_STEP_ONE"
            :gift-card-price="giftCardPrice"
            @submit="nextStep"
        >
            <template #title>
                {{ giftFormTitle }}
            </template>

            <template #description>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="giftFormDescription" />
            </template>
        </FormOrderGiftStepOne>

        <FormOrderGiftStepTwo
            v-show="currentStep === FORM_STEP_TWO"
            @back="previousStep"
            @submit="nextStep"
        />

        <FormOrderGiftStepThree
            v-show="currentStep === FORM_STEP_THREE"
            @back="previousStep()"
            @submit="submitGiftPayment"
        >
            <template
                v-if="currentStep === FORM_STEP_THREE"
                #info
            >
                <div>
                    <span>Aantal giftcards</span>
                    <span>{{ formData.amount }}</span>
                </div>

                <div>
                    <span>Totaalprijs</span>
                    <span>€{{ giftCardPrice * formData.amount }}</span>
                </div>

                <div>
                    <span>Naam</span>
                    <span>{{ formData.firstName }} {{ formData.lastName }}</span>
                </div>

                <div>
                    <span>Adres</span>

                    <span>
                        {{ formData.street }} {{ formData.streetNumber }}
                        <br>
                        {{ formData.zipCode }} {{ formData.city }}
                    </span>
                </div>
            </template>

            <template #termsAndConditionsLabel>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="giftTermsAndConditionsLabel" />
            </template>

            <template #amount>
                {{ formData.amount || 1 }} gift card{{ formData.amount > 1 ? 's' : '' }}
            </template>
        </FormOrderGiftStepThree>

        <p v-if="responseError">
            {{ STATUS['500'] }}
        </p>
    </div>
</template>

<script setup lang="ts">
import type { FlexibleOrderGiftFragment } from '~/@types/craft-schema';
import { STATUS } from '@/constants/errorMessages.js';
import { useHasSteps } from '~/composables/useHasSteps';

const FORM_STEP_ONE = 'step-one';
const FORM_STEP_TWO = 'step-two';
const FORM_STEP_THREE = 'step-three';
const FORM_STEP_FOUR = 'step-four';

const FORM_STEPS = [FORM_STEP_ONE, FORM_STEP_TWO, FORM_STEP_THREE, FORM_STEP_FOUR];

const props = defineProps<FlexibleOrderGiftFragment>();

const {
    formData,
    currentStep,
    currentStepNum,
    totalSteps,
    nextStep,
    previousStep
} = useHasSteps(FORM_STEPS, FORM_STEP_ONE);

const responseError = ref(false);

const progress = computed(() => {
        // In this specific case, the last step does not count towards the total steps.
        if (currentStepNum.value < totalSteps.value) {
            return `Stap ${currentStepNum.value} van ${totalSteps.value - 1}`;
        }
        return null;
    }),

    parsedFormData = computed(() => {
        return {
            successUrl: props.giftSuccessPage && props.giftSuccessPage[0]
                ? props.giftSuccessPage[0].url
                : null,
            failedUrl: props.giftFailedPage[0]
                ? props.giftFailedPage[0].slug
                : null,
            gift_price: props.giftCardPrice,
            amount_gift_boxes: formData.value?.amount,
            email: formData.value?.email,
            first_name: formData.value?.firstName,
            last_name: formData.value?.lastName,
            address: `${formData?.value.street} ${formData.value?.streetNumber}`,
            zip: formData.value?.zipCode,
            city: formData.value?.city
        };
    });

const submitGiftPayment = async() => {
    responseError.value = false;

    const response = await useLaravelApi('/v2.0/gift', {
        method: 'POST',
        body: parsedFormData.value
    }).catch((err) => {
        if (err) {
            responseError.value = true;
        }
    });

    if (!responseError.value) {
        navigateTo(response.payment_url, {
            external: true
        });
    }
};
</script>

<style lang="less" src="./FormOrderGift.less">
</style>
