<template>
    <DnButton
        class="button"
        :class="{
            'button--has-pre': !!$slots.pre,
            'button--has-after': !!$slots.after,
            'button--icon-only': !$slots.default && (!!$slots.pre || !!$slots.after)
        }"
        v-bind="$attrs"
    >
        <div
            v-if="!!$slots.pre"
            class="button__pre"
        >
            <slot name="pre" />
        </div>

        <slot />

        <div
            v-if="!!$slots.after"
            class="button__after"
        >
            <slot name="after" />
        </div>
    </DnButton>
</template>

<script>
import DnButton from '@digitalnatives/button';

export default {
    components: {
        DnButton
    }
};
</script>

<style src="./BaseButton.less" lang="less"></style>
