<template>
    <span
        :title="formattedLabel"
        class="event-date"
    >
        {{ formattedLabel }}
    </span>
</template>

<script>
import {
    parseISO,
    isToday,
    isTomorrow,
    isSameWeek,
    isThisYear,
    endOfToday,
    format,
    getDay
} from 'date-fns';

import { timeFormat } from '~/plugins/dates';

export default {
    props: {
        startDate: {
            type: String,
            default: null
        },
        endDate: {
            type: String,
            default: null
        }
    },

    computed: {
        startDateObj() {
            if (!this.startDate) {
                return;
            }

            return parseISO(this.startDate);
        },

        endDateObj() {
            if (!this.endDate) {
                return;
            }

            return parseISO(this.endDate);
        },

        startLabel() {
            // 1. Only start date: today
            if (isToday(this.startDateObj)) {
                return `Vandaag om ${format(this.startDateObj, timeFormat)}`;
            }

            // 2. Only start date: tomorrow
            if (isTomorrow(this.startDateObj)) {
                return `Morgen om ${format(this.startDateObj, timeFormat)}`;
            }

            // 3. Somewhere this week
            const thisWeek = isSameWeek(this.startDateObj, {
                weekStartsOn: getDay(endOfToday())
            });
            if (thisWeek) {
                return format(this.startDateObj, `EEEE 'om' ${timeFormat}`);
            }

            // Next year
            if (!isThisYear(this.startDateObj)) {
                return `${format(this.startDateObj, `EEEE d MMM yyyy 'om' ${timeFormat}`)}`;
            }

            // 5. Somewhere in the future (or past)
            return format(this.startDateObj, `EEEE d MMM 'om' ${timeFormat}`);
        },

        startEndLabel() {
            if (!this.endDateObj) {
                return;
            }

            // 1.1. Today, with end time
            if (isToday(this.startDateObj) && isToday(this.endDateObj)) {
                return `Vandaag van ${format(this.startDateObj, timeFormat)} tot ${format(this.endDateObj, timeFormat)}`;
            }

            // 1.2. Today, until tomorrow
            if (isToday(this.startDateObj) && isTomorrow(this.endDateObj)) {
                return `Vandaag van ${format(this.startDateObj, timeFormat)} tot morgen ${format(this.endDateObj, timeFormat)}`;
            }

            // 1.3. Today, until later this week
            const isWithinOneWeekOfToday = isSameWeek(this.endDateObj, {
                weekStartsOn: getDay(endOfToday())
            });
            if (isToday(this.startDateObj) && isWithinOneWeekOfToday) {
                return `Vandaag van ${format(this.startDateObj, timeFormat)} tot ${format(this.endDateObj, `EEEE 'om' ${timeFormat}`)}`;
            }

            // 1.4. Today, until after next week
            if (isToday(this.startDateObj)) {
                return `Vandaag van ${format(this.startDateObj, timeFormat)} tot ${format(this.endDateObj, `EEEE d MMM 'om' ${timeFormat}`)}`;
            }

            // 2.1. Tomorrow, with end time
            if (isTomorrow(this.startDateObj) && isTomorrow(this.endDateObj)) {
                return `Morgen van ${format(this.startDateObj, timeFormat)} tot ${format(this.endDateObj, timeFormat)}`;
            }

            // 2.2. Tomorrow - later this week
            const isWithinOneWeekOfTomorrow = isSameWeek(this.endDateObj, {
                weekStartsOn: getDay(endOfToday())
            });
            if (isTomorrow(this.startDateObj) && isWithinOneWeekOfTomorrow) {
                return `Morgen van ${format(this.startDateObj, timeFormat)} tot ${format(this.endDateObj, `EEEE ${timeFormat}`)}`;
            }

            // 2.3. Tomorrow - past next week
            if (isTomorrow(this.startDateObj)) {
                return `Morgen van ${format(this.startDateObj, timeFormat)} tot ${format(this.endDateObj, `EEEE d MMM ${timeFormat}`)}`;
            }

            // 3.1. Next year
            if (!isThisYear(this.endDateObj)) {
                return `${format(this.startDateObj, `EEEE d MMM ${timeFormat}`)} tot ${format(this.endDateObj, `dd MMM yyyy 'om' ${timeFormat}`)}`;
            }

            // 4. Somewhere in the future (or past)
            return `${format(this.startDateObj, `EEEE d MMM ${timeFormat}`)} tot ${format(this.endDateObj, `EEEE d MMM ${timeFormat}`)}`;
        },

        formattedLabel() {
            let formattedLabel;

            if (this.startEndLabel) {
                formattedLabel = this.startEndLabel;
            } else {
                formattedLabel = this.startLabel;
            }

            formattedLabel = formattedLabel[0].toUpperCase() + formattedLabel.substring(1);

            return formattedLabel;
        }
    }
};
</script>

<style src="./EventDate.less" lang="less"></style>
