import { defineStore } from 'pinia';
import type { EventDate } from '~/@types/data';
import { useUserStore } from './user';

// events
export const parsePrice = (price?: number|string|null) => {
    if (!price) {
        return '0';
    }

    if (typeof price === 'number') {
        price = price.toString();
    }

    if (price.search(/\.00/) > -1) {
        return price.replace(/\.00/, '');
    } else if (price.search(/\./) < 0) {
        return price;
    } else if (price.search(/\.\d{1}$/) > -1) {
        return price.replace(/\./, ',') + '0';
    }

    if (typeof price !== 'string') {
        return '0';
    }

    return price.replace(/\./, ',');
};

export const useEventsStore = defineStore('events', {
    state: () => ({
        activeEventId: null as null | number,
        activeEvent: null as null | EventDate,
        startPath: null as null | string,
        myListHomeChanged: 0,
        needsRefresh: 0,
        fairUsePolicyUri: '/' as string | null,
        ticketPricing: {
            priceRegular: null as number | null,
            parsedPriceRegular: null as string | null,
            price: null as number | null,
            parsedPrice: null as string | null,
            isFree: true,
            moneySaved: null as number | null,
            parsedMoneySaved: null as string | null,
            payPremium: false,
            needsPayment: false
        }
    }),

    getters: {
        isFullyBooked(): boolean {
            return this.activeEvent?.isFullyBooked ? true : false;
        },

        isWaitingListEnabled(): boolean {
            return this.isFullyBooked && this.activeEvent?.waitingListEnabled === true;
        }
    },

    actions: {
        deactivate() {
            this.activeEvent = null;
            this.activeEventId = null;
        },
    
        activate(payload: number|string|EventDate) {
            if (typeof payload === 'string') {
                this.activeEventId = parseInt(payload, 10);
            } else if (typeof payload === 'number') {
                this.activeEventId = payload;
            } else if (payload.id) {
                this.activeEventId = payload.id;
            }
        },
    
        setActiveEventData(payload: EventDate) {
            this.activeEvent = payload;

            this.setTicketPricingData(payload);
        },
    
        setStartPath(payload: string) {
            this.startPath = payload;
        },
    
        updateMyListHome() {
            ++this.myListHomeChanged;
        },

        setFairUsePolicyUri(uri: string) {
            this.fairUsePolicyUri = uri;
        },

        setTicketPricingData(event: EventDate) {
            const userStore = useUserStore();

            this.ticketPricing.isFree = event.freeForMembers;

            this.ticketPricing.payPremium = event.isPremium
                && !userStore.canMakeFreePremiumReservation;

            this.ticketPricing.needsPayment = !this.ticketPricing.isFree
                || this.ticketPricing.payPremium
                || (!event.canMakeFreeReservation && event.hasExtraCapacity);

            this.ticketPricing.priceRegular = event.ticketPriceRegular;

            if (this.ticketPricing.priceRegular) {
                this.ticketPricing.parsedPriceRegular = parsePrice(this.ticketPricing.priceRegular);
            }

            if (this.ticketPricing.payPremium && event.ticketPricePremium) {
                this.ticketPricing.price = event.ticketPricePremium;
            } else if (
                this.ticketPricing.needsPayment
                && !event.canMakeFreeReservation
                && event.ticketPriceExtraCapacity
                && event.hasExtraCapacity
            ) {
                this.ticketPricing.price = event.ticketPriceExtraCapacity;
            } else if (!event.freeForMembers && event.ticketPriceMembers) {
                this.ticketPricing.price = event.ticketPriceMembers;
            } 

            if (this.ticketPricing.price) {
                this.ticketPricing.parsedPrice = parsePrice(this.ticketPricing.price);
            }

            this.ticketPricing.moneySaved = Math.round(
                (
                    parseFloat(event.ticketPriceRegular?.toString(10) || '0') -
                    parseFloat(this.ticketPricing.price?.toString() || '0') +
                    Number.EPSILON
                ) * 100
            ) / 100;

            if (this.ticketPricing.moneySaved) {
                this.ticketPricing.parsedMoneySaved = parsePrice(this.ticketPricing.moneySaved);
            }
        },
    
        unsetTicketPricingData() {
            this.ticketPricing.priceRegular = null;
            this.ticketPricing.parsedPriceRegular = null;
            this.ticketPricing.price = null;
            this.ticketPricing.parsedPrice = null;
            this.ticketPricing.isFree = true;
            this.ticketPricing.moneySaved = null;
            this.ticketPricing.parsedMoneySaved = null;
            this.ticketPricing.payPremium = false;
            this.ticketPricing.needsPayment = false;
        }
    }
});
