<template>
    <div
        v-show="hasFilters"
        class="filter-state"
    >
        <button
            v-if="startDateFilterState"
            class="filter-state__filter button dn-button button--tertiary"
            type="button"
            @click="resetDateFilter"
        >
            {{ formattedDate }}

            <BaseIcon icon="cross" />
        </button>

        <button
            v-if="cityFilterState"
            class="filter-state__filter button dn-button button--tertiary"
            type="button"
            @click="resetCityFilter"
        >
            {{ formattedCity }}

            <BaseIcon icon="cross" />
        </button>

        <button
            v-if="regionFilterState"
            class="filter-state__filter button dn-button button--tertiary"
            type="button"
            @click="resetRegionFilter"
        >
            {{ formattedRegion }}

            <BaseIcon icon="cross" />
        </button>
    </div>
</template>

<script>
import { format, parse } from 'date-fns';
import capitalizeFirstLetter from '~/helpers/captitalizeFirstLetter';
import {
    THIS_WEEK,
    THIS_WEEKEND,
    NEXT_WEEK,
    NEXT_WEEKEND,
    RANGE_LABELS
} from '~/constants/dateOptions.js';
import { useEventFiltersStore } from '~/store/eventFilters';

export default {
    computed: {
        hasFilters() {
            return this.startDateFilterState || this.cityFilterState || this.regionFilterState;
        },

        cityOptions() {
            return useEventFiltersStore().cityOptions;
        },

        regionOptions() {
            return useEventFiltersStore().regionOptions;
        },

        formattedDate() {
            if (!this.startDateFilterState) {
                return;
            }

            if ([THIS_WEEK, THIS_WEEKEND, NEXT_WEEK, NEXT_WEEKEND].includes(this.startDateFilterState)) {
                return RANGE_LABELS[this.startDateFilterState];
            }

            // Add a separate today variable set to later today, so
            // it's in no way possible that a different time on the
            // server reverts to the previous day because of time difference.
            const todaySSR = new Date().setHours(10, 0, 0);
            const parsedDate = parse(this.startDateFilterState, 'dd-MM-yyyy', new Date(todaySSR));

            return capitalizeFirstLetter(format(parsedDate, 'eeee d MMMM'));
        },

        formattedCity() {
            if (!this.cityFilterState) {
                return;
            }

            const activeCity = this.cityOptions.find(city => city.value === this.cityFilterState);

            if (!activeCity) {
                return;
            }

            return activeCity.label;
        },

        formattedRegion() {
            if (!this.regionFilterState) {
                return;
            }

            const activeRegion = this.regionOptions.find(region => region.value === this.regionFilterState);

            if (!activeRegion) {
                return;
            }

            return activeRegion.label;
        },

        startDateFilterState() {
            if (!this.$route.query.startDate) {
                return;
            }
            return this.$route.query.startDate;
        },

        cityFilterState() {
            if (!this.$route.query.city) {
                return;
            }

            return parseInt(this.$route.query.city, 10);
        },

        regionFilterState() {
            if (!this.$route.query.region) {
                return;
            }

            return parseInt(this.$route.query.region, 10);
        }
    },

    methods: {
        resetDateFilter() {
            const query = { ...this.$route.query };

            delete query.startDate;

            this.$router.push({
                query
            });
        },

        resetCityFilter() {
            const query = { ...this.$route.query };

            delete query.city;

            this.$router.push({
                query
            });
        },

        resetRegionFilter() {
            const query = { ...this.$route.query };

            delete query.region;

            this.$router.push({
                query
            });
        }
    }
};
</script>

<style lang="less" src="./FilterState.less" />
