<template>
    <DnSlider>
        <!-- Pass down whatever slots are given to this component-->
        <template
            v-for="name in Object.keys($slots)"
            v-slot:[name]="scope"
        >
            <slot
                :name="name"
                v-bind="scope || {}"
            />
        </template>
    </DnSlider>
</template>

<script setup>
import DnSlider from '@digitalnatives/slider';
</script>

<style lang="less" src="./BaseSlider.less"></style>
