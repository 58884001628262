<template>
    <main
        name="content-page"
        class="template template__main"
    >
        <slot name="hero">
            <BaseHero
                :image="heroImage"
                :hero-event="heroEvent"
                :hero-link="entry?.heroLink"
            >
                <template
                    v-if="entry?.heroTitle"
                    #title
                >
                    {{ entry.heroTitle }}
                </template>
            </BaseHero>
        </slot>

        <slot name="filters">
            <Transition name="fade">
                <div
                    v-if="eventFiltersStore.filtersVisible"
                    class="template__filters"
                >
                    <FilterState />
                </div>
            </Transition>
        </slot>

        <slot name="default">
            <FlexibleSections
                v-if="entry?.flexibleSections || entry?.heroTitle"
                :sections="entry.flexibleSections ?? []"
            />
        </slot>
    </main>
</template>

<script lang="ts" setup>
import type { Page_Page_Entry, Page_ProgramOverview_Entry } from '~/@types/craft-schema';
import type { Media_Asset_Extended } from '~/@types/craft-schema.extended';
import { useEventFiltersStore } from '~/store/eventFilters';

const props = defineProps<{
    entry: Page_Page_Entry|Page_ProgramOverview_Entry|null;
}>();

const eventFiltersStore = useEventFiltersStore();

const heroEvent = computed(() => {
        if (!props.entry) {
            return null;
        }

        if (props.entry.heroEvent.length > 0) {
            return props.entry.heroEvent[0];
        }

        return null;
    }),

    heroImage = computed(() => {
        if (props.entry && props.entry.heroImages.length > 0) {
            return props.entry.heroImages[0] as Media_Asset_Extended;
        }

        return null;
    });

const toggleEventFilters = () => {
    if (
        props.entry &&
        props.entry.__typename === 'page_programOverview_Entry'
    ) {
        eventFiltersStore.filtersVisible = true;
    } else {
        eventFiltersStore.filtersVisible = false;
    }
};

onMounted(() => {
    toggleEventFilters();
});

onBeforeUnmount(() => {
    eventFiltersStore.filtersVisible = false;
});
</script>
