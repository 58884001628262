<template>
    <BaseButton
        class="button--secondary"
        @click="shareEmail"
    >
        E-mail
    </BaseButton>
</template>

<script>
export default {
    props: {
        subject: {
            type: String,
            default: null
        },
        body: {
            type: String,
            default: null
        }
    },

    methods: {
        shareEmail() {
            window.location.href = `mailto:?subject=${this.subject}&body=${this.body}`;
        }
    }
};
</script>

<style src="./ShareButtonEmail.less" lang="less"></style>
