<template>
    <div class="testimonial">
        <div class="testimonial__body">
            <blockquote v-show="content && content?.trim() !== ''">
                <p>
                    <slot name="content">
                        {{ content }}
                    </slot>
                </p>
            </blockquote>

            <div class="testimonial__meta">
                <div class="testimonial__image">
                    <div class="testimonial__image__inner">
                        <slot name="image">
                            <BaseImage
                                v-if="image"
                                class="base-image--cover"
                                :src="
                                    image.__typename === 'media_Asset'
                                        ? image.w64
                                        : image.url_md
                                "
                                :src-tiny="
                                    image.__typename === 'media_Asset'
                                        ? image.w12
                                        : image.url_xs
                                "
                                :srcset="imageSrcSet"
                                :webp-src-set="imageWebpSrcSet"
                                sizes="
                                    (max-width: 768px) 44px,
                                    64px
                                "
                                :width="image.width"
                                :height="image.height"
                                :title="title"
                                :alt="image.alt"
                            />
                        </slot>
                    </div>
                </div>
                <figcaption v-if="!!$slots.author || author">
                    <slot name="author">{{ author }}</slot>
                    <span
                        v-if="!!title"
                        class="testimonial__title"
                    >
                        <slot name="title">{{ title }}</slot>
                    </span>
                </figcaption>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { Media_Asset_Extended } from '~/@types/craft-schema.extended';
import type { CuratorFeaturedImage } from '~/@types/data';

const props = defineProps<{
    image?: Media_Asset_Extended | CuratorFeaturedImage | null;
    content?: string | null;
    author?: string | null;
    title?: string | null;
}>();

const imageSrcSet = computed(() => props?.image
    ? props.image.__typename && props.image.__typename === 'media_Asset'
        ? `
            ${props.image.w44} 44w,
            ${props.image.w64} 64w,
            ${props.image.w128} 2x
        `.trim()
        : `
            ${props.image.url_sm} 44w,
            ${props.image.url_md} 64w,
            ${props.image.url_lg} 2x
        `.trim()
    : null);

const imageWebpSrcSet = computed(() => props?.image
    ? props.image.__typename && props.image.__typename === 'media_Asset'
        ? `
            ${props.image.webp_w44} 44w,
            ${props.image.webp_w64} 64w,
            ${props.image.webp_w128} 2x
        `.trim()
        : `
            ${props.image.url_sm_webp} 44w,
            ${props.image.url_md_webp} 64w,
            ${props.image.url_lg_webp} 2x
        `.trim()
    : null);
</script>

<style src="./BaseTestimonial.less" lang="less"></style>
