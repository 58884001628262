<template>
    <div class="loader">
        <div class="loader__card" />
        <div class="loader__card" />
        <div class="loader__card" />
    </div>
</template>

<script>
export default {

};
</script>

<style lang="less" src="./BaseLoader.less" />
