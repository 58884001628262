<template>
    <BaseSection width="content-sm">
        <FormSignup
            v-if="signupDefinition"
            :signup-definition="signupDefinition"
        />
    </BaseSection>
</template>

<script setup lang="ts">
import type { FlexibleSignupFormFragment, SignupDefinitions_SignupDefinition_Entry } from '~/@types/craft-schema';

const props = defineProps<FlexibleSignupFormFragment>();

const signupDefinition = props.signupForm[0] as SignupDefinitions_SignupDefinition_Entry;
</script>
