import { useUserStore } from '~/store/user';

export default defineNuxtPlugin(async() => {
    const userStore = useUserStore();

    if (typeof userStore.jwtToken === 'string' && !userStore.user) {
        await userStore.fetchUser()
            .catch(async() => {
                try {
                    await userStore.fetchRefreshToken();
                    await userStore.fetchUser();
                } catch {
                    // jammer
                }
            });
    }
});
