<template>
    <figure
        class="base-image"
        :data-loaded="loaded ? 'true' : 'false'"
    >
        <div
            :style="{
                paddingBottom: `${ height/width*100 }%`
            }"
            class="base-image__wrapper"
        >
            <picture>
                <source
                    v-if="webpSrcset"
                    type="image/webp"
                    :srcset="webpSrcset"
                    :sizes="sizes"
                >

                <source
                    v-if="srcset"
                    :srcset="srcset"
                    :sizes="sizes"
                >

                <img
                    class="base-image__highres"
                    :src="src"
                    :alt="alt || title || ''"
                    :title="title || ''"
                    :width="width"
                    :height="height"
                    :style="imgStyleAttrs"
                    loading="lazy"
                    @load="loaded = true"
                >
            </picture>

            <div
                v-if="srcTiny"
                :style="{
                    backgroundImage: `url('${ srcTiny }')`,
                    ...bgStyleAttrs
                }"
                class="base-image__lowres"
            />
        </div>

        <figcaption
            v-if="caption || credits"
            class="base-image__caption"
        >
            <!-- eslint-disable -->
            <span
                v-if="caption"
                v-html="caption"
            />

            <span v-if="credits && caption"> - </span>

            <span
                v-if="credits"
                v-html="credits"
            />
            <!-- eslint-enable -->
        </figcaption>
    </figure>
</template>

<script setup lang="ts">
interface Props {
    src?: string;
    srcset?: string | null;
    webpSrcset?: string | null;
    sizes?: string;
    srcTiny?: string;
    title?: string|null;
    alt?: string|null;
    caption?: string|null;
    width: number;
    height: number;
    credits?: string|null;
    hasFocalPoint?: boolean;
    focalPoint?: number[];
}

const props = withDefaults(
    defineProps<Props>(),
    {
        title: '',
        focalPoint: () => [],
        srcTiny: undefined,
        sizes: undefined,
        webpSrcset: null,
        srcset: null,
        src: undefined,
        alt: null,
        caption: null,
        credits: null,
        hasFocalPoint: false,
    }
);

const loaded = ref(import.meta.server);

const imgStyleAttrs = computed(() => {
        if (props.hasFocalPoint && props.focalPoint) {
            return `object-position: ${focalPointAttr.value}`;
        }

        return '';
    }),

    bgStyleAttrs = computed(() => {
        if (props.hasFocalPoint && props.focalPoint) {
            return {
                'background-position': focalPointAttr.value
            };
        }

        return {};
    }),

    focalPointAttr = computed(() => {
        if (props.hasFocalPoint && props.focalPoint && props.focalPoint.length === 2) {
            return `${props.focalPoint[0] * 100}% ${props.focalPoint[1] * 100}%`;
        }

        return '';
    });
</script>

<style lang="less" src="./BaseImage.less" />
