import { isValidIBAN} from 'ibantools';
import {
    required as vRequired,
    email as vEmail,
    regex as vRegex,
    min as vMin
} from '@vee-validate/rules';
import { defineRule } from 'vee-validate';

defineRule('regex', (value: any, [regularExpression]: string|RegExp[]) => {
    if (!vRegex(value, { regex: regularExpression })) {
        return 'Ongeldige waarde';
    }

    return true;
});

defineRule('email', (value: any) => {
    if (!vEmail(value)) {
        return 'Voer een geldig e-mailadres in';
    }

    return true;
});

defineRule('min', (value: any, [minit]: number[]) => {
    if (!vMin(value, { length: minit })) {
        return `Dit veld moet uit minimaal ${minit} tekens bestaan`;
    }

    return true;
});

defineRule('iban', (value: any) => {
    if (typeof value === 'string' && !isValidIBAN(value.trim().toUpperCase())) {
        return 'Dit is geen geldig IBAN nummer';
    }

    return true;
});

defineRule('required', (value: any) => {
    if (!vRequired(value)) {
        return 'Dit veld is verplicht';
    }

    return true;
});

defineRule('zipCode', (value: any) => {
    if (typeof value === 'string' && value.match(/[0-9]{4} ?[A-Z]{2}/ig)) {
        return true;
    }

    return 'Dit is geen geldige postcode';
});

export default defineNuxtPlugin(() => {});