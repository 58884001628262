<template>
    <BaseForm
        class="order-gift-step-two"
        @submit="onSubmit"
    >
        <template #title>
            Waar kunnen we de giftcard(s) naar toe sturen?
        </template>

        <template #content>
            <FormFieldColumns
                data-columns-mobile="2"
                data-columns-desktop="2"
            >
                <FieldValidator
                    v-slot="{ field, meta, errors, handleReset }"
                    name="street"
                >
                    <BaseFormField
                        :required="true"
                        :errors="errors"
                    >
                        <template #label>Straatnaam</template>

                        <BaseInput
                            v-bind="field"
                            type="text"
                            :validator="meta"
                            @clear="handleReset"
                        />
                    </BaseFormField>
                </FieldValidator>

                <FieldValidator
                    v-slot="{ field, meta, errors, handleReset }"
                    name="streetNumber"
                >
                    <BaseFormField
                        :required="true"
                        :errors="errors"
                    >
                        <template #label>Huisnummer</template>
                        <BaseInput
                            v-bind="field"
                            type="text"
                            :validator="meta"
                            @clear="handleReset"
                        />
                    </BaseFormField>
                </FieldValidator>
            </FormFieldColumns>

            <FormFieldColumns
                data-columns-mobile="2"
                data-columns-desktop="2"
            >
                <FieldValidator
                    v-slot="{ field, meta, errors, handleReset }"
                    name="zipCode"
                >
                    <BaseFormField
                        :required="true"
                        :errors="errors"
                    >
                        <template #label>Postcode</template>
                        <BaseInput
                            v-bind="field"
                            type="text"
                            :validator="meta"
                            @clear="handleReset"
                        />
                    </BaseFormField>
                </FieldValidator>

                <FieldValidator
                    v-slot="{ field, meta, errors, handleReset }"
                    name="city"
                >
                    <BaseFormField
                        :required="true"
                        :errors="errors"
                    >
                        <template #label>Plaats</template>
                        <BaseInput
                            v-bind="field"
                            type="text"
                            :validator="meta"
                            @clear="handleReset"
                        />
                    </BaseFormField>
                </FieldValidator>
            </FormFieldColumns>
        </template>

        <template #actions>
            <BaseButton
                type="button"
                @click.prevent="emit('back')"
            >
                Vorige stap
            </BaseButton>

            <BaseButton class="form__submit button--secondary">
                Volgende stap
            </BaseButton>
        </template>
    </BaseForm>
</template>

<script setup lang="ts">
import { useForm, Field as FieldValidator } from 'vee-validate';

const emit = defineEmits(['submit', 'back']);

const { handleSubmit } = useForm({
    validationSchema: {
        street: 'required',
        streetNumber: 'required',
        zipCode: 'required|zipCode',
        city: 'required'
    }
});

const onSubmit = handleSubmit((formData) => {
    emit('submit', formData);
});
</script>

<style lang="less" src="./FormOrderGiftStepTwo.less">
</style>
