import { defineStore } from 'pinia';
import useLaravelApi from '~/composables/useLaravelApi';
import FilterSettingsQuery from '~/graphql/queries/FilterSettings.graphql';
import type { CityFilterOptions, RegionFilterOptions } from '~/@types/data';
import type { Page_ProgramOverview_Entry } from '~/@types/craft-schema';

export const useEventFiltersStore = defineStore('eventFilters', {
    state: () => ({
        filtersVisible: false,
        filterOptions: null as null | {
            region: RegionFilterOptions;
            city: CityFilterOptions;
        },
        filterOptionsFetched: false,
        filterSettingsFetched: false,
        mainProgramEntry: null as null | Page_ProgramOverview_Entry
    }),

    getters: {
        cityOptions(): CityFilterOptions {
            if (!this.filterOptions || !this.filterOptions.city) {
                return [];
            }

            const optionsClone = [...this.filterOptions.city];

            // Sort alphabetically
            optionsClone.sort((a, b) => {
                const labelA = a.label.toUpperCase();
                const labelB = b.label.toUpperCase();

                return (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0;
            });

            return optionsClone;
        },

        regionOptions(): RegionFilterOptions  {
            if (!this.filterOptions || !this.filterOptions.region) {
                return [];
            }

            return this.filterOptions.region;
        }
    },

    actions: {
        async fetchFilterOptions() {
            // Don't refetch if prefences were already fetched
            if (this.filterOptionsFetched) {
                return;
            }
    
            
            const data = await useLaravelApi('/v2.0/events/filters');
    
            if (data) {
                this.filterOptions = data;
                this.filterOptionsFetched = true;
            }
        },
    
        async fetchFilterSettings() {
            // Don't refetch if settings were already fetched
            if (this.filterSettingsFetched) {
                return;
            }

            const response = await useNuxtApp().runWithContext(async() => {
                const { data } = await useAsyncQuery({
                    query: FilterSettingsQuery
                });

                return data;
            });

            if (
                response &&
                response?.globalSet &&
                response?.globalSet?.mainProgramEntry &&
                response?.globalSet?.mainProgramEntry?.length > 0
            ) {
                this.filterSettingsFetched = true;
                this.mainProgramEntry = response.globalSet.mainProgramEntry[0];
            }
        }
    }
});
