<template>
    <div class="form">
        <BasePanel :class="panelClass">
            <template #title>
                <slot name="title" />
            </template>

            <div
                v-if="$slots.before"
                class="form__before"
            >
                <slot name="before" />
            </div>

            <form
                class="form__form"
                method="post"
                @submit.prevent="emit('submit')"
            >
                <slot name="content" />

                <div
                    v-if="$slots.errors"
                    class="form__error"
                >
                    <slot name="errors" />
                </div>

                <div
                    v-if="$slots.actions"
                    class="form__actions"
                >
                    <slot name="actions" />
                </div>
            </form>

            <div
                v-if="$slots.after"
                class="form__after"
            >
                <slot name="after" />
            </div>
        </BasePanel>
    </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(['submit']);

defineProps<{
    panelClass?: 'panel--profile' | 'panel--sheeeet';
}>();
</script>

<style lang="less" src="./BaseForm.less" />
