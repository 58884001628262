<template>
    <NuxtLink
        v-if="collection"
        :to="{
            name: 'collectie-slug',
            params: {
                slug: collection.slug
            }
        }"
        class="collection-card"
        :class="{
            'collection-card--featured': isFeatured
        }"
    >
        <div class="collection-card__stack">
            <BaseImage
                v-if="imgLayerThree"
                :alt="imgLayerThree.alt ? imgLayerThree.alt : imgLayerThree.title"
                :src="imgLayerThree.w320"
                :srcset="imgLayerThree.srcset"
                :webp-srcset="imgLayerThree.webpSrcset"
                :width="imgLayerThree.width"
                :height="imgLayerThree.height"
                class="base-image--cover"
                data-layer-number="3"
            />

            <BaseImage
                v-if="imgLayerTwo"
                :alt="imgLayerTwo.alt ? imgLayerTwo.alt : imgLayerTwo.title"
                :src="imgLayerTwo.w320"
                :srcset="imgLayerTwo.srcset"
                :webp-srcset="imgLayerTwo.webpSrcset"
                :width="imgLayerTwo.width"
                :height="imgLayerTwo.height"
                class="base-image--cover"
                data-layer-number="2"
            />

            <BaseImage
                v-if="imgLayerOne"
                :alt="imgLayerOne.alt ? imgLayerOne.alt : imgLayerOne.title"
                :src="imgLayerOne.w320"
                :srcset="imgLayerOne.srcset"
                :webp-srcset="imgLayerOne.webpSrcset"
                :width="imgLayerOne.width"
                :height="imgLayerOne.height"
                class="base-image--cover"
                data-layer-number="1"
            />
        </div>

        <div class="collection-card__body">
            <component
                :is="isFeatured ? 'h2' : 'h3'"
                class="collection-card__title"
            >
                {{ collection.title }}
            </component>

            <!-- eslint-disable -->
            <div
                v-if="isFeatured && collection.previewText"
                class="collection-card__description"
                v-html="collection.previewText"
            />
            <!-- eslint-enable -->

            <div
                v-if="curator"
                class="collection-card__curator"
            >
                <BaseImage
                    v-if="curatorFeaturedImage"
                    :src="curatorFeaturedImage"
                    :srcset="curatorFeaturedSrcset"
                    :webp-srcset="curatorFeaturedWebpSrcset"
                    :width="isFeatured ? 64 : 44"
                    :height="isFeatured ? 64 : 44"
                    class="collection-card__curator__image base-image--cover"
                />

                <div class="collection-card__curator__info">
                    <h4>
                        {{ curator.curatorName }}
                    </h4>

                    <p>
                        {{ curator.curatorSubTitle }}
                    </p>
                </div>
            </div>
        </div>
    </NuxtLink>
</template>

<script setup lang="ts">
import type { Collection_Default_Entry, Curator_Category } from '~/@types/craft-schema';
import type { Media_Asset_Extended } from '~/@types/craft-schema.extended';
import BaseImage from '~/components/Base/Image/BaseImage.vue';

const props = defineProps<{
    collection: Collection_Default_Entry;
    isFeatured?: boolean;
}>();

const featuredImages = props.collection.collectionFeaturedImages as Media_Asset_Extended[];

const imgLayerSrcset = (index: number) => {
        if (props.isFeatured) {
            return `
                ${featuredImages[index].url_hero_xs} 576w,
                ${featuredImages[index].url_hero_sm} 768w
            `.trim();
        }

        return `
            ${featuredImages[index].w320} 320w,
            ${featuredImages[index].url_hero_xs} 576w
        `.trim();
    },

    imgLayerWebpSrcset = (index: number) => {
        if (props.isFeatured) {
            return `
                ${featuredImages?.[index].webp_url_hero_xs} 576w,
                ${featuredImages?.[index].webp_url_hero_sm} 768w
            `.trim();
        }

        return `
            ${featuredImages?.[index].webp_w320} 320w,
            ${featuredImages?.[index].webp_url_hero_xs} 576w
        `.trim();
    };

const imgLayerOne = props.collection.collectionFeaturedImages[0]
        ? {
            srcset: imgLayerSrcset(0),
            webpSrcset: imgLayerWebpSrcset(0),
            ...featuredImages[0]
        }
        : null,

    imgLayerTwo = props.collection.collectionFeaturedImages[1]
        ? {
            srcset: imgLayerSrcset(1),
            webpSrcset: imgLayerWebpSrcset(1),
            ...featuredImages[1]
        }
        : null,

    imgLayerThree = props.collection.collectionFeaturedImages[2]
        ? {
            srcset: imgLayerSrcset(2),
            webpSrcset: imgLayerWebpSrcset(2),
            ...featuredImages[2]
        }
        : null,

    curator = props.collection.collectionCurator[0] as Curator_Category,

    curatorFeaturedImage = curator
        ? props.isFeatured
            ? curator.curatorImageUrlMd
            : curator.curatorImageUrlSm
        : null,

    curatorFeaturedSrcset = curator
        ? props.isFeatured
            ? `
                ${curator.curatorImageUrlMd} 1x,
                ${curator.curatorImageUrlMd_2x} 2x
            `.trim()
            : `
                ${curator.curatorImageUrlSm} 1x,
                ${curator.curatorImageUrlSm_2x} 2x
            `.trim()
        : null,

    curatorFeaturedWebpSrcset = curator
        ? props.isFeatured
            ? `
                ${curator.curatorImageUrlMdWebp} 1x,
                ${curator.curatorImageUrlMdWebp_2x} 2x
            `.trim()
            : `
                ${curator.curatorImageUrlSmWebp} 1x,
                ${curator.curatorImageUrlSmWebp_2x} 2x
            `.trim()
        : null;
</script>

<style lang="less" src="./CollectionCard.less">
</style>
