<template>
    <BaseSection
        width="content-lg"
        class="section--no-overflow"
        :color-theme="colorTheme"
    >
        <LazyCallToActionBlock
            :call-to-action="callToAction"
            :secondary-call-to-action="secondaryCallToAction"
            :text="richText"
            :image="image"
            :title="sectionTitle"
            :theme="colorTheme"
            v-bind="$attrs"
            @click="emit('click')"
        />
    </BaseSection>
</template>

<script setup lang="ts">
import type { FlexibleCallToActionFragment } from '~/@types/craft-schema';
import type { Media_Asset_Extended } from '~/@types/craft-schema.extended';

interface Props extends FlexibleCallToActionFragment {
    image: Media_Asset_Extended[];
}

const props = defineProps<Props>();

const emit = defineEmits(['click']);

const image = props.image[0] || null;
</script>
