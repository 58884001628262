<template>
    <div class="panel">
        <h3
            v-if="$slots.title"
            class="panel__title"
        >
            <slot name="title" />
        </h3>

        <div class="panel__content">
            <slot />
        </div>

        <div
            v-if="$slots.after"
            class="panel__after"
        >
            <slot name="after" />
        </div>
    </div>
</template>

<style lang="less" src="./BasePanel.less" />
