export type FormDataRecord = Record<string, any>;

export const useHasSteps = (
    stepsArray: string[],
    firstStep: string
) => {
    const steps = ref<string[]>(stepsArray),
        currentStep = ref<string>(firstStep),
        formData = ref<FormDataRecord>({}),
        hasError = ref(false);

    const currentStepNum = computed(() => {
            return steps.value.indexOf(currentStep.value) + 1;
        }),

        totalSteps = computed(() => {
            return steps.value.length;
        });

    const setStep = (step: string) => {
            if (steps.value.includes(step)) {
                currentStep.value = step;
            }
        },

        nextStep = (payload?: FormDataRecord) => {
            if (payload) {
                // Merge the payload of the form with our form data.
                formData.value = { ...formData.value, ...payload };
            }

            const index = steps.value.indexOf(currentStep.value);

            if (steps.value[index + 1]) {
                currentStep.value = steps.value[index + 1];
            }
        },

        previousStep = () => {
            const index = steps.value.indexOf(currentStep.value);

            hasError.value = false;

            if (steps.value[index - 1]) {
                currentStep.value = steps.value[index - 1];
            }
        };

    return {
        formData,

        steps,
        currentStep,

        currentStepNum,
        totalSteps,

        setStep,
        nextStep,
        previousStep
    };
};
