import { defineStore } from 'pinia';
import type { Ether_SeoData, FairUsePolicy_GlobalSet, Membership_GlobalSet } from '~/@types/craft-schema';

export const useGlobalStore = defineStore('global', {
    state: () => ({
        fetched: false,

        seo: null as Ether_SeoData | null,
        fairUserPolicy: null as FairUsePolicy_GlobalSet | null,

        membershipTexts: {
            cancellationText: 'Klik op de gele knop om je opzegging te bevestigen.',
            confirmText: 'Bevestigen',
            closeText: 'Annuleren',
            becomeMemberCtaLoginModal: 'Nog geen account? Word lid.',
            becomeMemberCtaLoginModalSmallText: 'Als je lid wordt van We Are Public krijg je gratis toegang tot alle progamma\'s op ons platform.',
            becomeMemberCtaDefault: 'Word lid'
        },

        maxReservations: 5,
    }),

    actions: {
        setMembershipTextData(payload: Membership_GlobalSet) {
            if (payload.richText) {
                this.membershipTexts.cancellationText = payload.richText;
            }

            if (payload.textPlain) {
                this.membershipTexts.confirmText = payload.textPlain;
            }

            if (payload.textPlain2) {
                this.membershipTexts.confirmText = payload.textPlain2;
            }

            if (payload.textPlain3) {
                this.membershipTexts.becomeMemberCtaLoginModal = payload.textPlain3;
            }
    
            if (payload.textPlain4) {
                this.membershipTexts.becomeMemberCtaDefault = payload.textPlain4;
            }

            if (payload.textPlain5) {
                this.membershipTexts.becomeMemberCtaLoginModalSmallText = payload.textPlain5;
            }
        },

        setMaxReservations(payload: number) {
            this.maxReservations = payload;
        }
    }
});
