<template>
    <p>
        <span v-if="ticketPricing.isFree && !ticketPricing.needsPayment">
            Tickets zijn gratis met de We Are Public-pas
            <br>
        </span>

        <span v-if="ticketPricing.needsPayment && ticketPricing.parsedPrice">
            We Are Public prijs: €{{ ticketPricing.parsedPrice }}
            <br>
        </span>

        <span v-if="ticketPricing.priceRegular">
            Reguliere prijs: €{{ ticketPricing.parsedPriceRegular }}
            <br>
        </span>
    </p>
</template>

<script setup lang="ts">
import type { EventDate } from '~/@types/data';
import { useEventsStore } from '~/store/events';

defineProps<{
    event: EventDate;
}>();

const eventsStore = useEventsStore();

const ticketPricing = computed(() => eventsStore.ticketPricing);
</script>
