<template>
    <StateSwitch
        :state="status"
        :status-code="statusCode"
    >
        <slot
            name="default"
            :entries="entries"
        />
    </StateSwitch>
</template>

<script setup lang="ts">
import CollectionsQuery from '~/graphql/queries/Collections.graphql';
import { useCraftQuery, type CraftResponseObject } from '~/composables/useCraftQuery';
import type { Collection_Default_Entry } from '~/@types/craft-schema';

const {
    collectionTypeId = null
} = defineProps<{
    collectionTypeId?: string | null;
}>();

const filters = computed(() => {
    if (collectionTypeId) {
        return {
            collectionTypeId
        };
    }

    return {};
});

const response = await useAsyncQuery({
    query: CollectionsQuery,
    variables: filters
});

interface ResponseObject extends CraftResponseObject {
    entries: Ref<Collection_Default_Entry[]>;
}

const {
    status,
    statusCode,
    entries
} = await useCraftQuery(response) as ResponseObject;
</script>
