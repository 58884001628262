import type { Ether_SeoData, Footer_GlobalSet, MainNavigation_GlobalSet, Membership_GlobalSet } from '~/@types/craft-schema';
import GlobalsQuery from '~/graphql/queries/Globals.graphql';
import { useEventsStore } from '~/store/events';
import { useGlobalStore } from '~/store/global';
import { useNavigationStore } from '~/store/navigation';

function logMissingGlobalSet(globalSetName: string) {
    if (import.meta.server) {
        // eslint-disable-next-line no-console
        console.warn(`Coulnd't retrieve (${globalSetName})`);
    }
}

export default defineNuxtPlugin(async() => {
    const globalStore = useGlobalStore();

    if (!globalStore.fetched) {
        /**
         * Craft data
         */
        const { data: craftData, error: craftError } = await useAsyncQuery({
            query: GlobalsQuery
        });

        if (craftError.value) {
            console.error('Coulnd\'t retrieve craft global data in plugins/globalData.ts');
        }

        if (!craftData.value) {
            return;
        }

        const navigationStore = useNavigationStore();

        const {
            seo,
            membership,
            mainNavigation,
            footer,
            fairUsePolicy
        } = craftData.value;

        globalStore.fetched = true;
    
        if (!seo?.seo) {
            logMissingGlobalSet('Ether_SeoData');
        } else {
            globalStore.seo = seo.seo as Ether_SeoData;
        }

        if (!membership) {
            logMissingGlobalSet('Membership_GlobalSet');
        } else {
            globalStore.setMembershipTextData(membership as Membership_GlobalSet);
        }

        if (!mainNavigation) {
            logMissingGlobalSet('MainNavigation_GlobalSet');
        } else {
            navigationStore.setMainNavigation(mainNavigation as MainNavigation_GlobalSet);
        }

        if (!footer) {
            logMissingGlobalSet('Footer_GlobalSet');
        } else {
            navigationStore.setFooter(footer as Footer_GlobalSet);
        }

        if (!fairUsePolicy?.entry?.[0]?.uri) {
            logMissingGlobalSet('FairUsePolicy_GlobalSet');
        } else {
            useEventsStore().setFairUsePolicyUri(fairUsePolicy.entry[0].uri as string);
        }

        /**
         * Laravel data
         */
        const laravelResponse = await useLaravelApi('/v2.0/global-data');

        if (!laravelResponse) {
            console.error('Coulnd\'t retrieve laravel global data in plugins/globalData.ts');
        }

        if (laravelResponse.max_reservations) {
            globalStore.setMaxReservations(laravelResponse.max_reservations);
        }
    }
});
