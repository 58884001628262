<template>
    <BaseForm
        class="payment-info"
        @submit="submitPaymentInfo"
    >
        <template
            v-if="$slots.title"
            #title
        >
            <slot name="title" />
        </template>

        <template #content>
            <template v-if="$slots.content">
                <slot name="content" />
            </template>

            <div class="payment-info__membership">
                <div>
                    <span>Lidmaatschap</span>
                    <span>{{ membership }}</span>
                </div>

                <div>
                    <span>Prijs</span>
                    <span>{{ price }}</span>
                </div>

                <div>
                    <span>Startdatum</span>
                    <span>{{ startDate }}</span>
                </div>

                <div>
                    <span>Naam</span>
                    <span>{{ name }}</span>
                </div>

                <div>
                    <span>Adres</span>
                    <span v-html="address" /> <!-- eslint-disable-line -->
                </div>
            </div>

            <FieldValidator
                v-slot="{ field, meta, errors }"
                name="iban"
            >
                <BaseFormField
                    :required="true"
                    :errors="errors"
                >
                    <template #label>IBAN rekeningnummer</template>

                    <BaseInput
                        v-bind="field"
                        :validator="meta"
                    />

                    <template #info>
                        {{ ibanAuthorizationText }}
                    </template>
                </BaseFormField>
            </FieldValidator>
        </template>

        <template #actions>
            <BaseButton
                type="button"
                @click.prevent="emit('backToPrevious')"
            >
                Vorige stap
            </BaseButton>

            <BaseButton
                :disabled="isSubmitting"
                type="submit"
                class="form__submit button--secondary"
            >
                Bevestigen

                <template #after>
                    <BaseIcon icon="arrow-right" />
                </template>
            </BaseButton>
        </template>

        <template
            v-if="!!$slots.errors"
            #errors
        >
            <slot name="errors" />
        </template>
    </BaseForm>
</template>

<script setup lang="ts">
import { useForm, Field as FieldValidator } from 'vee-validate';
import {
    REGULAR as FORM_REGULAR,
} from '@/constants/signUpFormTypes.js';
import type { Maybe } from '~/@types/craft-schema';

interface Props {
    membership?: Maybe<string>;
    price?: Maybe<string>;
    startDate?: Maybe<string>;
    name?: Maybe<string>;
    address?: Maybe<string>;
    ibanAuthorizationText?: Maybe<string>;
    formType?: Maybe<string>;
    isSubmitting: boolean;
}

withDefaults(defineProps<Props>(), {
    isSubmitting: false,
    formType: FORM_REGULAR,

    membership: undefined,
    price: undefined,
    startDate: undefined,
    name: undefined,
    address: undefined,
    ibanAuthorizationText: undefined
});

const emit = defineEmits(['submit', 'backToPrevious']);

const { handleSubmit } = useForm({
    validationSchema: {
        iban: 'required|iban'
    }
});

const submitPaymentInfo = handleSubmit((formData) => {
    emit('submit', formData);
});
</script>

<style lang="less" src="./FormPaymentInfo.less" />
