import { defineStore } from 'pinia';

export const useSearchStore = defineStore('search', {
    state: () => ({
        previousRoute: null as null | string
    }),

    actions: {
        setPreviousRoute(payload: string) {
            this.previousRoute = payload;
        },

        clearPreviousRoute() {
            this.previousRoute = null;
        }
    }
});
