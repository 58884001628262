import { useEventsStore } from '~/store/events';
import { MODAL_MAIN, useModalStore } from '~/store/modal';

export default defineNuxtRouteMiddleware((to, from) => {
    if (
        import.meta.server
        && typeof to.query.programma === 'string'
    ) {
        return navigateTo('/programma/' + decodeURIComponent(to.query.programma), {
            replace: true
        });
    }

    const modalStore = useModalStore(),
        eventsStore = useEventsStore();

    if (
        import.meta.client
        && modalStore.activeModals[MODAL_MAIN]
        && eventsStore.activeEventId
        && (
            from.query.programma
            && !to.query.programma
        )
    ) {
        return abortNavigation();
    }
});
