import { defineStore } from 'pinia';
import type { Footer_GlobalSet, MainNavigation_GlobalSet } from '~/@types/craft-schema';

export const useNavigationStore = defineStore('navigation', {
    state: () => ({
        mainNavigation: null as null | MainNavigation_GlobalSet,
        footer: null as null | Footer_GlobalSet
    }),

    actions: {
        setMainNavigation(payload: MainNavigation_GlobalSet) {
            this.mainNavigation = payload;
        },

        setFooter(payload: Footer_GlobalSet) {
            this.footer = payload;
        }
    }
});
