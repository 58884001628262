<template>
    <div
        v-inview
        class="hero"
        :data-has-image="!!image"
    >
        <div class="hero__header">
            <div
                v-if="image"
                class="hero__image"
                :data-has-credits="!!heroImage.credits"
            >
                <section
                    v-if="heroEvent"
                    class="hero__event"
                >
                    <div class="hero__event__card">
                        <section
                            v-if="partnerLogo"
                            class="hero__event__partner-logo"
                        >
                            <BaseImage
                                :src="partnerLogo.url_hero_xxs"
                                :webp-srcset="partnerLogo.webp_url_hero_xxs"
                                :width="partnerLogo.width"
                                :height="partnerLogo.height"
                                :title="partnerLogo.title"
                                :alt="partnerLogo.alt"
                            />
                        </section>

                        <section>
                            <div
                                v-if="heroEvent.heroEventDate"
                                class="hero__event__date"
                            >
                                <EventDate :start-date="heroEvent.heroEventDate" />
                            </div>

                            <div
                                v-if="heroEvent.heroEventTitle	"
                                class="hero__event__title"
                            >
                                {{ heroEvent.heroEventTitle }}
                            </div>

                            <div
                                v-if="heroEvent.heroEventArtist"
                                class="hero__event__artist"
                            >
                                {{ heroEvent.heroEventArtist }}
                            </div>
                        </section>
                    </div>
                </section>

                <!-- eslint-disable -->
                <div
                    v-if="heroImage.credits"
                    class="hero__image__credits"
                    v-html="`Beeld: ${heroImage.credits}`"
                />
                <!-- eslint-enable -->

                <a
                    :href="
                        heroLink?.url
                            ? heroLink.url
                            : ''
                    "
                >
                    <BaseImage
                        v-if="image"
                        class="base-image--cover"
                        :src="heroImage.url_hero_lg"
                        :src-tiny="heroImage.url_hero_tiny"
                        :webp-srcset="baseHeroImageWebpSrcset"
                        :srcset="`
                            ${heroImage.url_hero_xs} 576w,
                            ${heroImage.url_hero_sm} 768w,
                            ${heroImage.url_hero_md} 992w,
                            ${heroImage.url_hero_lg} 1114w
                        `.trim()"
                        :width="heroImage.width"
                        :height="heroImage.height"
                        :title="heroImage.title"
                        :alt="heroImage.alt"
                    />
                </a>
            </div>

            <div class="hero__header__body">
                <!-- Sorry for the nesting madness. Needed for pretty gradients. -->
                <div class="hero__header__body__inner">
                    <!-- Pre title -->
                    <div
                        v-if="!!$slots.preTitle"
                        class="hero__pre-title"
                    >
                        <slot name="preTitle" />
                    </div>

                    <!-- Title -->
                    <h1
                        v-if="!!$slots.title"
                        class="hero__title"
                    >
                        <slot name="title" />
                    </h1>
                </div>
            </div>
        </div>

        <div
            v-if="!!$slots.subTitle || !!$slots.description"
            class="hero__body"
        >
            <div class="hero__body__inner">
                <!-- Sub title -->
                <div
                    v-if="!!$slots.subTitle"
                    class="hero__sub-title"
                >
                    <slot name="subTitle" />
                </div>

                <!-- Description -->
                <div
                    v-if="!!$slots.description"
                    class="hero__description"
                >
                    <slot name="description" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { Maybe } from 'graphql/jsutils/Maybe';
import type { AssetInterface, HeroEvent_BlockType, LinkField_Link } from '~/@types/craft-schema';
import type { Media_Asset_Extended } from '~/@types/craft-schema.extended';

const props = defineProps<{
    heroLink?: Maybe<LinkField_Link>;
    heroEvent?:  Maybe<HeroEvent_BlockType>;
    image?: Maybe<AssetInterface>;
}>();

const heroImage = props.image as Media_Asset_Extended;

const partnerLogo = computed(() => {
    if (props.heroEvent && props.heroEvent.heroPartnerLogo.length > 0) {
        return props.heroEvent.heroPartnerLogo[0] as Media_Asset_Extended;
    }

    return null;
});

const baseHeroImageWebpSrcset = computed(() => {
    if (heroImage.webp_url_hero_lg && heroImage.webp_url_hero_md) {
        return `
            ${heroImage.webp_url_hero_xs} 576w,
            ${heroImage.webp_url_hero_sm} 768w,
            ${heroImage.webp_url_hero_md} 992w,
            ${heroImage.webp_url_hero_lg} 1114w
        `.trim();
    }

    return null;    
});
</script>

<style lang="less" src="./BaseHero.less" />
