<template>
    <BaseButton
        class="share-button-whatsapp button--whatsapp"
        @click="shareWhatsapp"
    >
        <template #default>WhatsApp</template>
        <template #after><BaseIcon icon="whatsapp" /></template>
    </BaseButton>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            default: null
        }
    },

    methods: {
        shareWhatsapp() {
            window.location.href = `whatsapp://send?text=${encodeURIComponent(this.message)}`;
        }
    }
};
</script>

<style src="./ShareButtonWhatsapp.less" lang="less"></style>
