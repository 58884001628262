<template>
    <BaseSlider
        v-if="collections && collections.length > 0"
        :items="collections"
        class="collection-slider"
    >
        <template #slide="{ item }">
            <CollectionCard
                :collection="item"
                drag-to-scroll="ignore"
                draggable="false"
            />
        </template>
    </BaseSlider>
</template>

<script>
export default {
    props: {
        collections: {
            type: Array,
            default: null
        },
    }
};
</script>

<style lang="less" src="./CollectionSlider.less">
</style>
