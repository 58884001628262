<template>
    <div
        class="columns"
        :class="{
            'columns--reversed': isReversed
        }"
        :data-column-count="slotNames.length"
    >
        <div
            v-for="slotName in slotNames"
            :key="slotName"
            class="columns__column"
        >
            <slot :name="slotName" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isReversed: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        slotNames() {
            return Object.keys(this.$slots);
        }
    }
};
</script>

<style lang="less" src="./BaseColumns.less" />
