<template>
    <div
        :class="{
            'dn-checkbox--disabled': disabled
        }"
        class="dn-checkbox"
    >
        <label>
            <input
                ref="input"
                v-model="model"
                type="checkbox"
                v-bind="$attrs"
            >

            <span class="dn-checkbox__custom">
                <slot
                    name="custom"
                    :is-checked="isSelected"
                />
            </span>

            <span
                v-if="!!$slots.default"
                class="dn-checkbox__label"
            >
                <slot :is-checked="isSelected" />
            </span>
        </label>
    </div>
</template>

<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        falseValue?: any;
        trueValue?: any;
        disabled?: boolean;
    }>(),
    {
        falseValue: false,
        trueValue: true,
        disabled: false
    }
);

const model = defineModel<any>();

const input = ref<HTMLInputElement|null>();

const isSelected = computed(() => {
    if (!input.value) {
        return false;
    }

    if (Array.isArray(model.value)) {
        return model.value.includes(input.value.value);
    }

    if (input.value.value) {
        return model.value === input.value.value;
    }

    return model.value === props.trueValue;
});
</script>

<style src="./BaseCheckbox.less" lang="less"></style>
