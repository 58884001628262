<template>
    <span
        title="Je hebt dit programma opgeslagen"
        class="saved-label"
    >
        <BaseIcon icon="saved" />
    </span>
</template>

<style src="./SavedLabel.less" lang="less"></style>
