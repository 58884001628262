<template>
    <BasePanel class="invite-a-friend">
        <template #title>
            {{ panelTitle }}
        </template>

        <template #default>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="description" />

            <div
                v-if="shareUrl"
                class="invite-a-friend__shares"
            >
                <div>
                    Nodig iemand uit via onderstaande links
                </div>

                <div class="invite-a-friend__buttons">
                    <ShareButtonEmail
                        subject="Vriendendienst"
                        :body="emailShareText"
                    />

                    <ShareButtonWhatsapp :message="whatsappShareText" />
                </div>

                <div>
                    Of kopieer de link hieronder en deel deze
                </div>

                <CopyShareInput :share-url="shareUrl" />
            </div>
        </template>
    </BasePanel>
</template>

<script setup lang="ts">
import { replace } from 'lodash-es';
import type { FlexibleInviteAFriendFragment } from '~/@types/craft-schema';
import { useUserStore } from '~/store/user';

const props = defineProps<FlexibleInviteAFriendFragment>();

const userStore = useUserStore();

const { baseURL } = useRuntimeConfig().public;

const shareUrl = computed(() => {
        if (props.entry?.[0]?.slug && userStore.user) {
            return `${baseURL}/${props.entry[0].slug}?sharedBy=${userStore.user.referrer_token}`;
        } else if (props.entry?.[0]?.slug) {
            return `${baseURL}/${props.entry[0].slug}`;
        }

        return '';
    }),

    socialTexts = computed(() => {
        return props.shareTexts?.[0];
    }),

    emailShareText = computed(() => {
        if (!socialTexts.value || !socialTexts.value.emailShareText) {
            return;
        }

        return replaceUrlToken(socialTexts.value.emailShareText);
    }),

    whatsappShareText = computed(() => {
        if (!socialTexts.value || !socialTexts.value.whatsappShareText) {
            return;
        }

        return replaceUrlToken(socialTexts.value.whatsappShareText);
    });

const replaceUrlToken = (text: string) => {
    return replace(text, '{url}', shareUrl.value);
};
</script>

<style lang="less" src="./FormInviteAFriend.less"></style>
