<template>
    <!-- eslint-disable vue/no-unused-vars -->
    <a
        :href="`/programma/${event.uri}`"
        class="event-card"
        :class="{
            'event-card--cancelled': event.isCancelled,
            'event-card--list-view': appearance === 'list',
            'event-card--with-pricing': withPricing
        }"
        @click.prevent="showEventDetail"
    >
        <!-- eslint-enable vue/no-unused-vars -->
        <div class="event-card__header">
            <div class="event-card__image">
                <BaseImage
                    v-if="featuredImage"
                    class="base-image--cover"
                    :src="imageSrc"
                    :srcset="imageSrcset"
                    :webp-srcset="imageWebpSrcset"
                    :width="featuredImage.width || 270"
                    :height="featuredImage.height || 337"
                    :alt="featuredImage.alt"
                />
            </div>

            <div
                v-if="appearance === 'box' && event.disciplines && event.disciplines.length"
                class="event-card__header__disciplines"
            >
                <BaseLabel class="label--text">
                    <EventDisciplines :event="event" />
                </BaseLabel>
            </div>

            <div
                v-if="appearance === 'box' && label"
                class="event-card__label"
            >
                <BaseLabel>{{ label }}</BaseLabel>
            </div>

            <div
                v-if="isReserved || isSaved"
                class="event-card__actions"
            >
                <SavedLabel v-if="isSaved" />
                <ReservedLabel v-if="isReserved" />
            </div>
        </div>

        <div class="event-card__body">
            <div
                v-if="appearance === 'list' && event.disciplines && event.disciplines.length"
                class="event-card__body__disciplines"
            >
                <EventDisciplines :event="event" />
            </div>

            <EventMeta :event="event" />
        </div>

        <div
            v-if="useCanShowPricing(event) && withPricing && event.ticketPriceRegular"
            class="event-card__pricing"
        >
            <span class="event-card__pricing__regular">
                <template v-if="event.ticketPriceRegular">
                    {{ '€' + parsePrice(event.ticketPriceRegular) }}
                </template>

                <template v-else>
                    €0
                </template>
            </span>

            <span class="event-card__pricing__paid">
                {{ event.paidPrice ? ('€' + parsePrice(event.paidPrice)) : 'Gratis' }}
            </span>
        </div>
    </a>
</template>

<script setup lang="ts">
import { MODAL_MAIN, useModalStore } from '~/store/modal';
import { useUserStore } from '~/store/user';
import type { EventDate } from '~/@types/data';
import { useEventsStore, parsePrice } from '~/store/events';

interface Props {
    event: EventDate;
    appearance?: 'box' | 'list';
    withPricing?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    appearance: 'box',
    withPricing: false
});

const route = useRoute();

const userStore = useUserStore();
const modalStore = useModalStore();
const eventsStore = useEventsStore();

const label = computed(() => {
        if (props.event.programLine) {
            return props.event.programLine.name;
        }

        return null;
    }),

    isReserved = computed(() => {
        return userStore.hasReservation(props.event.id);
    }),

    isSaved = computed(() => {
        return userStore.hasFavorite(props.event.id);
    }),

    featuredImage = computed(() => {
        return props.event?.featuredImage;
    }),

    imageSrc = computed(() => {
        if (!featuredImage.value) {
            return '';
        }

        if (props.appearance === 'list') {
            return featuredImage.value.url_list_md;
        }

        return featuredImage.value.url_box_md;
    }),

    imageSrcset = computed(() => {
        if (!featuredImage.value) {
            return;
        }

        if (props.appearance === 'list') {
            return `
                ${featuredImage.value.url_list_md} 80w,
                ${featuredImage.value.url_list_lg} 2x
            `.trim();
        }

        return `
            ${featuredImage.value.url_box_md} 270w,
            ${featuredImage.value.url_box_lg} 2x
        `.trim();
    }),

    imageWebpSrcset = computed(() => {
        if (!featuredImage.value) {
            return;
        }

        if (
            props.appearance === 'list' &&
            featuredImage.value.url_list_md_webp &&
            featuredImage.value.url_list_lg_webp
        ) {
            return `
                ${featuredImage.value.url_list_md_webp} 80w,
                ${featuredImage.value.url_list_lg_webp} 2x
            `.trim();
        }

        if (
            featuredImage.value.url_list_md_webp &&
            featuredImage.value.url_list_lg_webp
        ) {
            return `
                ${featuredImage.value.url_box_md_webp} 270w,
                ${featuredImage.value.url_box_lg_webp} 2x
            `.trim();
        }

        return undefined;
    });

const showEventDetail = async(event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (eventsStore.activeEventId === null) {
        eventsStore.setStartPath(route.path);
    }

    modalStore.open({ name: MODAL_MAIN });

    eventsStore.activate(props.event.id);

    await navigateTo({
        query: {
            programma: encodeURIComponent(props.event.uri)
        }
    }, {
        replace: true
    });
};
</script>

<style lang="less" src="./EventCard.less"></style>
