<template>
    <div
        v-bind="extendedWrapperAttrs"
        class="dn-input base-input"
        :class="{
            'base-input--has-floating-label': !!label
        }"
        @click="onClickWrapper"
    >
        <div
            v-if="hasPre"
            class="base-input__pre"
        >
            <slot name="pre" />
        </div>

        <div class="base-input__field">
            <div class="base-input__field-body">
                <input
                    ref="input"
                    v-bind="extendedInputAttrs"
                    @focus="onFocusInput"
                    @blur="onBlurInput"
                >

                <label
                    v-if="label"
                    class="base-input__label"
                >
                    {{ label }}
                </label>
            </div>

            <Transition name="base-input-fade">
                <div
                    v-if="showClearButton || hasStatus"
                    class="base-input__field-aside"
                >
                    <Transition name="base-input-fade">
                        <button
                            v-if="showClearButton"
                            key="clear"
                            type="button"
                            class="base-input__clear"
                            title="Reset"
                            tabindex="-1"
                            @click="emit('clear')"
                        >
                            <BaseIcon icon="cross" />
                        </button>
                    </Transition>

                    <Transition name="base-input-fade">
                        <div
                            v-if="hasStatus"
                            v-bind="slotProps"
                            class="base-input__status"
                        >
                            <slot name="status" />
                        </div>
                    </Transition>
                </div>
            </Transition>
        </div>

        <div
            v-if="hasAfter"
            class="dn-input__after base-input__after"
        >
            <slot name="after" />
        </div>
    </div>
</template>

<script setup>
const NOT_VALIDATED = 'not-validated';
const INVALID = 'invalid';
const VALID = 'valid';
const VALIDATION_DISABLED = 'disabled';

const props = defineProps({
    modelValue: {
        type: String,
        default: null
    },

    validator: {
        type: Object,
        default: null
    },

    /**
     * UI related
     */
    label: {
        type: String,
        default: null
    },

    clearable: {
        type: Boolean,
        default: true
    },

    forceFloat: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['update:modal-value', 'change', 'clear', 'blur', 'focus']);

const attrs = useAttrs();
const slots = useSlots();

const input = useTemplateRef('input');

const isFocussed = ref(false);

const hasPre = !!slots.pre;

const hasAfter = !!slots.after;

const hasValue = computed(() => {
        return attrs.value !== null && attrs.value !== undefined && attrs.value !== '';
    }),

    hasLabel = computed(() => {
        return props.label !== null && props.label !== undefined && props.label !== '';
    }),

    hasStatus = computed(() => {
        return !!slots.status;
    }),

    showClearButton = computed(() => {
        return props.clearable && hasValue.value;
    }),

    isFloating = computed(() => {
        return props.forceFloat || (hasLabel.value && (isFocussed.value || hasValue.value));
    }),

    validationState = computed(() => {
        if (props.validator === null) {
            return VALIDATION_DISABLED;
        }

        if (props.validator.validated && props.validator.valid) {
            return VALID;
        }

        if (props.validator.validated && !props.validator.valid) {
            return INVALID;
        }

        if (!props.validator.validated) {
            return NOT_VALIDATED;
        }

        return NOT_VALIDATED;
    });

const onFocusInput = () => {
        emit('focus');
        isFocussed.value = true;
    },

    onBlurInput = () => {
        isFocussed.value = false;
    },

    onClickWrapper = () => {
        if (input.value) {
            input.value.focus();
        }
    };

const extendedWrapperAttrs = computed(() => ({
    'data-focus': isFocussed.value,
    'data-disabled': !!attrs.disabled,
    'data-readonly': !!attrs?.readonly,
    'data-floating': isFloating.value,
    'data-has-pre': hasPre,
    'data-has-after': hasAfter,
    'data-validation': validationState.value
}));

const extendedInputAttrs = computed(() => ({
    autofocus: false,
    ...attrs
}));
</script>

<style src="./BaseInput.less" lang="less"></style>
