<template>
    <span class="event-artists">
        {{ formattedLabel }}
    </span>
</template>

<script>
export default {
    props: {
        artists: {
            type: Array,
            default: null
        }
    },

    computed: {
        formattedLabel() {
            if (!this.artists || !this.artists.length) {
                return;
            }

            if (this.artists.length === 1) {
                return this.artists[0].name;
            }

            const artistNames = this.artists.map(artist => artist.name);
            return artistNames.join(', ');
        }
    }
};
</script>
