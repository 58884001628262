import { defineStore } from 'pinia';

export const MODAL_MAIN = 'main';
export const MODAL_SHEET = 'sheet';
export const MODAL_LOGIN = 'login';
export const MODAL_RESERVATION = 'reservation';
export const MODAL_USER_CARD_SHEET = 'userCardSheet';

export const useModalStore = defineStore('modal', {
    state: () => ({
        activeModals: {} as {[key: string]: boolean},
        data: {} as {[key: string]: any}
    }),

    actions: {
        close(payload: string|{name: string}) {
            const modalName = typeof payload === 'string'
                ? payload
                : payload.name;

            if (modalName && this.activeModals[modalName]) {
                this.activeModals[modalName] = false;
            }

            if (modalName && this.data[modalName]) {
                this.data[modalName] = null;
            }

            document.body.setAttribute(`data-modal-${modalName}-open`, 'false');

            if (Object.values(this.activeModals).filter(value => value).length === 0) {
                document.body.removeAttribute('data-prevent-scrolling');
            }
        },
    
        /**
         * Open specific modal
         *
         * The payload can either be a string matching a modal's name,
         * or an object containing a 'name' property. When using
         * the object any data you add is accessible using the
         * 'modal/data' getter in your component.
         *
         * @param   {String|Object}  payload  String or object containing modal name.
         */
        open(payload: string|null|{name: string, data?: any}) {
            // If no modal name is present, reset it.
            if (!payload || (typeof payload !== 'string' && !payload?.name)) {
                this.activeModals = {};
                return;
            }

            let modalName: string|null = null;
    
            // If the payload is a string, just interpret that as the modal's name.
            if (typeof payload === 'string') {
                modalName = payload;
            } else if (payload?.name) {
                modalName = payload.name;

                if (payload?.data) {
                    this.data[modalName] = payload.data;
                }
            }

            if (modalName) {
                this.activeModals[modalName] = true;

                document.body.setAttribute('data-prevent-scrolling', 'true');
                document.body.setAttribute(`data-modal-${modalName}-open`, 'true');
            }
        },

        closeAll() {
            this.activeModals = {};
            this.data = {};
        }
    }
});
