<template>
    <QueryEventBundle
        :lazy="lazy"
        :query="eventBundleQuery"
    >
        <template #default="{ events, loadMore, lastPageNumber, currentPageNumber, totalResults }">
            <BaseSection
                v-if="events && events.length > 0"
                :width="sectionWidth"
                :color-theme="presentation === 'list' ? 'dark' : colorTheme"
                :class="{
                    'section--event-list': presentation === 'list'
                }"
                data-spacing="lg"
            >
                <template
                    v-if="title"
                    #title
                >
                    {{ title }}
                </template>

                <template
                    v-if="callToAction && callToAction.url"
                    #readMore
                >
                    <NavLink
                        :type="mapType(callToAction.type)"
                        :url="mapURI(callToAction)"
                        :blank="isExternal(callToAction)"
                    >
                        {{ callToAction.text || 'Bekijk alles' }}
                    </NavLink>
                </template>

                <template #default>
                    <EventSlider
                        v-if="presentation === 'slider'"
                        key="results"
                        :events="events"
                        :card-size="cardSize"
                    />

                    <EventList
                        v-else-if="presentation === 'list'"
                        :events="events"
                        :total-results="totalResults"
                        :current-page-number="currentPageNumber"
                        :last-page-number="lastPageNumber"
                        class="event-list--end-of-page"
                    >
                        <template
                            v-if="lastPageNumber && currentPageNumber < lastPageNumber"
                            #loadMore
                        >
                            <BaseButton
                                type="button"
                                class="event-list__load-more button--clean"
                                @click="loadMore"
                            >
                                Toon meer programma's
                            </BaseButton>
                        </template>

                        <template
                            v-else-if="events.length === totalResults"
                            #noResults
                        >
                            <p
                                class="event-list__no-results"
                            >
                                Je hebt alle programma's gezien
                            </p>
                        </template>
                    </EventList>

                    <EventGrid
                        v-else-if="presentation === 'grid'"
                        :events="events"
                    >
                        <template
                            v-if="lastPageNumber && currentPageNumber < lastPageNumber"
                            #loadMore
                        >
                            <BaseButton
                                type="button"
                                class="event-grid__load-more button--clean"
                                @click="loadMore"
                            >
                                Toon meer programma's
                            </BaseButton>
                        </template>

                        <template
                            v-else-if="events.length === totalResults"
                            #noResults
                        >
                            <p
                                class="event-grid__no-results"
                            >
                                Je hebt alle programma's gezien
                            </p>
                        </template>
                    </EventGrid>
                </template>
            </BaseSection>
        </template>

        <template #loading>
            <BaseLoader />
        </template>
    </QueryEventBundle>
</template>

<script setup lang="ts">
import type { EventBundleFragment, FlexibleEventBlockFragment } from '~/@types/craft-schema';

interface Props extends FlexibleEventBlockFragment {
    lazy?: boolean;
}

const props = withDefaults(
    defineProps<Props>(),
    {
        lazy: true
    }
);

const { mapType, mapURI, isExternal } = useNavigation();

const presentation = props.eventBlockPresentationType;

const sectionWidth = computed(() => {
    if (props.eventBlockPresentationType === 'slider') {
        return 'bleed';
    }

    return 'page';
});

const eventBundleQuery = props.eventBundleEntry &&
    props.eventBundleEntry.length > 0 &&
    props.eventBundleEntry[0]?.__typename === 'eventBundles_default_Entry'
    ? props.eventBundleEntry[0].eventBundle[0] as EventBundleFragment
    : props.eventBundle[0] as EventBundleFragment;
</script>
