import { defineStore } from 'pinia';
import type { User } from '~/@types/data';

export const useEmailConfirmStore = defineStore('emailConfirm', {
    state: () => ({
        user: null as User|null,
        email: null as String|null,
        token: null as String|null
    }),

    actions: {
        setUser(payload: User) {
            this.user = payload;
        },
    
        setEmail(payload: string) {
            this.email = payload;
        },
    
        setToken(payload: string) {
            this.token = payload;
        }
    }
});
