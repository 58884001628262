<template>
    <div class="signup">
        <template v-if="allowedToSignup">
            <div
                v-if="progress"
                class="signup__progress"
            >
                {{ progress }}
            </div>
            <!-- Account details -->
            <FormAccountDetails
                v-if="currentStep === STEP_ACCOUNT_DETAILS"
                :form-type="signupDefinition?.formType"
                :authorization="signupDefinition.authorizationText"
                :custom-field-enabled="signupDefinition.enableCustomField"
                :custom-field-label="signupDefinition.customFieldLabel"
                :custom-field-description="signupDefinition.customFieldDescription"
                :campaign="campaignId"
                @submit="nextStep"
            />

            <FormVerifyCode
                v-if="currentStep === STEP_VERIFY"
                @next-step="nextStep"
                @back-to-start="previousStep()"
            >
                <template #title>
                    {{ signupDefinition.stepTitle }}
                </template>

                <template #before>
                    <span v-html="signupDefinition.stepSubtitle" /><!-- eslint-disable-line -->
                </template>
            </FormVerifyCode>

            <!-- User details -->
            <FormUserDetails
                v-if="currentStep === STEP_USER_DETAILS"
                v-model="prefilledUserDetails"
                :show-physical-pass-field="signupDefinition.formType !== FORM_GIFT"
                @submit="nextStep"
            />

            <!-- Payment info -->
            <FormPaymentInfo
                v-if="currentStep === STEP_PAYMENT_INFO"
                :membership="signupDefinition.membershipText"
                :price="signupDefinition.priceText"
                :start-date="signupDefinition.startDateText"
                :name="`${formData.first_name} ${formData.last_name}`"
                :address="`${formData.street} ${formData.street_number}<br>${formData.zip} ${formData.city}`"
                :form-type="signupDefinition.formType"
                :iban-authorization-text="signupDefinition.ibanAuthorizationText"
                :is-submitting="isSubmitting"
                @back-to-previous="previousStep()"
                @submit="submitSignup"
            >
                <template #title>
                    {{ signupDefinition.stepTitle2 }}
                </template>

                <template #content>
                    <span v-html="signupDefinition.stepSubtitle2" /><!-- eslint-disable-line -->
                </template>

                <template
                    v-if="hasError"
                    #errors
                >
                    Er ging helaas iets fout. Probeer het later nog eens, of stuur een mailtje naar
                    <a href="mailto:info@wearepublic.nl?subject=Er ging iets fout tijdens mijn aanmelding">
                        info@wearepublic.nl
                    </a>.
                </template>
            </FormPaymentInfo>

            <!-- Success -->
            <BasePanel
                v-if="currentStep === STEP_SUCCESS
                    && !shouldAutomaticallyRedirectOnSuccess"
            >
                <template #title>{{ signupDefinition.stepTitle3 }}</template>
                <!-- eslint-disable -->
                <p class="panel__notice" v-html="signupDefinition.confirmationText" />
                <!-- eslint-enable -->

                <template #after>
                    <NavLink
                        v-if="signupDefinition.callToAction"
                        class="button dn-button button--secondary form__button--max-width"
                        :type="mapType(signupDefinition.callToAction.type)"
                        :url="mapURI(signupDefinition.callToAction)"
                        :blank="isExternal(signupDefinition.callToAction)"
                    >
                        {{ signupDefinition.callToAction.customText || signupDefinition.callToAction.text }}
                    </NavLink>
                </template>
            </BasePanel>
        </template>

        <template v-else>
            <BasePanel>
                <template #title>
                    <template v-if="referralError">
                        Code ongeldig
                    </template>
                </template>

                <template #default>
                    <p v-if="referralError">
                        Helaas! Je kunt je niet aanmelden met deze code.
                        Wellicht kun je je vriend(in) vragen om nogmaals zijn of haar share link met je te delen.
                    </p>
                </template>
            </BasePanel>
        </template>
    </div>
</template>

<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm';
import type { SignupDefinitions_SignupDefinition_Entry } from '~/@types/craft-schema';
import { GIFT as FORM_GIFT } from '~/constants/signUpFormTypes.js';
import { useEmailConfirmStore } from '~/store/emailConfirm';
import { useSignUpStore } from '~/store/signUp';

const STEP_ACCOUNT_DETAILS = 'account-details',
    STEP_VERIFY = 'verify',
    STEP_USER_DETAILS = 'user-details',
    STEP_PAYMENT_INFO = 'payment-info',
    STEP_SUCCESS = 'success';

const STEPS = [STEP_ACCOUNT_DETAILS, STEP_VERIFY, STEP_USER_DETAILS, STEP_PAYMENT_INFO, STEP_SUCCESS];

const props = defineProps<{
    signupDefinition: SignupDefinitions_SignupDefinition_Entry;
}>();

const { mapType, mapURI, isExternal } = useNavigation();

const {
    formData,

    currentStep,
    currentStepNum,
    totalSteps,

    nextStep,
    previousStep
} = useHasSteps(STEPS, STEP_ACCOUNT_DETAILS);

const route = useRoute();

const isSubmitting = ref(false),
    hasError = ref(false);

const token = computed(() => {
    return useEmailConfirmStore().token;
});

const referrerToken = computed(() => {
    if (!route.query || !route.query.sharedBy) {
        return;
    }

    return route.query.sharedBy;
});

const membershipStatus = computed(() => {
    return useSignUpStore().membershipStatus;
});

const progress = computed(() => {
    // In this specific case, the last step does not count towards the total steps.
    if (currentStepNum.value < totalSteps.value) {
        return `Stap ${currentStepNum.value} van ${totalSteps.value - 1}`;
    }
    return null;
});

const prefilledUserDetails = computed(() => {
    return { ...formData.value };
});

const signUpEndpoint = computed(() => {
    if (props.signupDefinition.formType === FORM_GIFT) {
        return '/v2.0/gift/activate';
    } else if (membershipStatus.value !== null) {
        return '/v2.0/user/renew';
    }

    return '/v2.0/user/sign-up';
});

const referralError = computed(() => {
    if (props.signupDefinition.referralCodeRequired && !referrerToken.value) {
        return true;
    }

    return false;
});

const allowedToSignup = computed(() => {
    if (referralError.value) {
        return false;
    }

    return true;
});

const shouldAutomaticallyRedirectOnSuccess = computed(() => {
    if (!props.signupDefinition || !props.signupDefinition.successRedirect) {
        return false;
    }

    return !!props.signupDefinition.successRedirect[0]?.uri;
});

const campaignId = computed(() => {
    if (
        Array.isArray(props.signupDefinition.userCampaign) &&
        props.signupDefinition.userCampaign.length > 0
    ) {
        return props.signupDefinition.userCampaign[0]?.id;
    }

    return null;
});

const submitSignup = async(payload: FormDataRecord) => {
    isSubmitting.value = true;

    // Add last step to form data
    formData.value = { ...payload, ...formData.value };

    // Add campaign ID to form data, if available
    if (
        props.signupDefinition.userCampaign.length > 0
        && props.signupDefinition.userCampaign[0]
    ) {
        formData.value.campaign_id = props.signupDefinition.userCampaign[0].id;
    }

    if (props.signupDefinition.formType) {
        formData.value.formType = props.signupDefinition.formType;
    }

    await useLaravelApi(signUpEndpoint.value, {
        method: 'POST',
        body: {
            token: token.value,
            referrer_token: referrerToken.value,
            ...formData.value
        }
    }).catch((err) => {
        if (err?.response?.data?.exception === 'Mandrill_Unknown_Template') {
            hasError.value = false;
        } else {
            hasError.value = true;
        }

        isSubmitting.value = false;
    });

    if (hasError.value === false) {
        isSubmitting.value = false;
        nextStep();
    }
};

const redirectToSuccessPage = () => {
    if (!shouldAutomaticallyRedirectOnSuccess.value && !props.signupDefinition.successRedirect?.[0]?.uri) {
        return;
    }

    if (props.signupDefinition.successRedirect?.[0]?.uri) {
        navigateTo({
            path: props.signupDefinition.successRedirect[0].uri
        });
    }
};

watch(currentStep, (value) => {
    // If the next step is the SUCCESS step, redirect to the succes page
    // BUT: this only happens when an automatic success page entry is
    // set in the CMS. Else the last 'success' step will be displayed
    // within the panel.
    if (value === STEP_SUCCESS && shouldAutomaticallyRedirectOnSuccess.value) {
        redirectToSuccessPage();
    }

    // Send event to GTM to measure current signup step
    // Note: this doesn't track anything by default.
    // They should be handled within GTM or GA4
    if (value) {
        useGtm()?.push({
            event: 'signupStep',
            signupStep: value
        });
    }
}, {
    immediate: true
});
</script>

<style lang="less" src="./FormSignup.less" />
