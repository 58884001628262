<template>
    <StateSwitch :state="status">
        <template #default>
            <slot
                v-if="events && events.length > 0"
                :events="events"
                :load-more="loadMore"
                :total-results="totalResults"
                :last-page-number="lastPageNumber"
                :current-page-number="currentPageNumber"
            />
        </template>

        <template #loading>
            <slot name="loading" />
        </template>

        <template #error>
            <slot name="error">
                <NoResults class="no-results--centered">
                    Er ging iets fout tijdens het laden van de programma's.
                </NoResults>
            </slot>
        </template>
    </StateSwitch>
</template>

<script setup lang="ts">
import type { EventDate } from '~/@types/data';
import { useEventBundle, type ComponentProps } from '~/composables/useEventBundle';

const props = withDefaults(
    defineProps<ComponentProps>(),
    {
        lazy: true
    }
);

const emit = defineEmits(['show', 'hide']);

const {
    totalResults,
    currentPageNumber,
    lastPageNumber,
    notFound,
    filterQuery
} = useEventBundle(props);

const events = ref<EventDate[]|null>();

const eventsEndpoint = computed(() => {
    return '/v2.0/events?' + new URLSearchParams({
        page: currentPageNumber.value.toString(),
        ...filterQuery.value
    });
});

const fetchEvents = async() =>{
    return await useLaravelApi(eventsEndpoint.value);
};

const loadMore = async() => {
    ++currentPageNumber.value;

    const response = await fetchEvents();

    if (events.value) {
        events.value = [
            ...events.value,
            ...response.events
        ];
    } else {
        events.value = response.events as EventDate[];
    }
};

const { data, status } = await useAsyncData(
    `event-bunlde-${JSON.stringify(filterQuery.value)}`,
    fetchEvents,
    {
        watch: [filterQuery],
        lazy: !props.lazy
    }
);

watch(status, () => {
    if (currentPageNumber.value === 1 && status.value === 'success') {
        events.value = data.value.events as EventDate[];
        totalResults.value = data.value.meta.total as number;
        lastPageNumber.value = data.value.meta.lastPage as number;

        if (totalResults.value === 0) {
            emit('hide');
        } else {
            emit('show');
        }
    }
}, {
    immediate: true
});

if (['success', 'error'].includes(status.value)) {
    notFound.value = true;
}
</script>
