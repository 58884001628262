import { villusBugsnag, craftPreview, bearerToken } from '@digitalnatives/villus-plugins';
import { cache, dedup } from 'villus';
import { batch } from '@villus/batch';
export default defineGraphqlConfig(() => {
    const config = useRuntimeConfig();

    return {
        villus: {
            url: config.public.apiEndpoint as string,
            use: [
                villusBugsnag(),
                bearerToken(config.public.apiToken),
                craftPreview(),
                batch(),
                cache(),
                dedup()
            ],
        }
    };
});
