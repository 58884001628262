<template>
    <BaseForm @submit="submitCode">
        <template
            v-if="$slots.title"
            #title
        >
            <slot name="title" />
        </template>

        <template
            v-else
            #title
        >
            Check je inbox
        </template>

        <template
            v-if="$slots.before"
            #before
        >
            <slot name="before" />
        </template>

        <template
            v-else
            #before
        >
            We hebben je een e-mail gestuurd met een verificatiecode.
            Vul de code hieronder in om je e-mail adres te verifieren.
        </template>

        <template #content>
            <FieldValidator
                v-slot="{ field, meta, errors }"
                name="code"
            >
                <BaseFormField
                    :errors="errors"
                >
                    <template #label>
                        Code
                    </template>

                    <BaseInput
                        v-bind="field"
                        type="text"
                        :validator="meta"
                    />
                </BaseFormField>
            </FieldValidator>

            <div
                v-if="!!errorMessage"
                class="form__error"
            >
                {{ errorMessage }}
            </div>
        </template>

        <template #actions>
            <BaseButton
                type="submit"
                class="form__submit button--secondary form__button--max-width"
            >
                Volgende stap
            </BaseButton>
        </template>

        <template #after>
            Het kan een paar minuten duren voordat je de code ontvangen hebt.
            <br><br>
            Geen code ontvangen? Check je spam of
            <BaseButton
                type="button"
                class="button button--link"
                @click.prevent="emit('backToStart')"
            >
                probeer nogmaals
            </BaseButton>.
        </template>
    </BaseForm>
</template>

<script setup lang="ts">
import {
    STATUS,
    PASSWORD_RESET_INVALID_CODE,
    PASSWORD_RESET_TOO_MANY_ATTEMPTS,
} from '~/constants/errorMessages';
import { useForm, Field as FieldValidator } from 'vee-validate';
import { useEmailConfirmStore } from '~/store/emailConfirm';

const emit = defineEmits(['nextStep', 'backToStart']);

const errorMessage = ref<string|null>(null);

const emailConfirmationStore = useEmailConfirmStore();

const { handleSubmit } = useForm<{code: string}>({
    validationSchema: {
        code: 'required'
    }
});

const submitCode = handleSubmit(async(formData) => {
    errorMessage.value = null;

    const response = await useLaravelApi('/v2.0/user/email-confirmation/check', {
        method: 'post',
        body: {
            email: emailConfirmationStore.email,
            token: formData.code
        }
    })
        .catch((error) => {
            if (error?.response?.status === 429) {
                errorMessage.value = PASSWORD_RESET_TOO_MANY_ATTEMPTS;
            } else if (error?.response?.status === 400) {
                errorMessage.value = PASSWORD_RESET_INVALID_CODE;
            } else {
                errorMessage.value = STATUS['500'];
            }
        });

    if (response.user) {
        emailConfirmationStore.setUser(response.user);
    }

    if (response.token) {
        emailConfirmationStore.setToken(response.token);
    }

    if (!errorMessage.value) {
        emit('nextStep');
    }
});
</script>
