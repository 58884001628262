import type { SeoFragment } from '~/@types/craft-schema';
import { useGlobalStore } from '~/store/global';

export default async function useEntrySeo(seo: SeoFragment|null) {
    const globalStore = useGlobalStore();

    const defaultSeo = globalStore.seo;

    const title = seo?.title || defaultSeo?.title || 'We Are Public';

    const metaData = {
        title,
        meta: [
            {
                hid: 'description',
                name: 'description',
                content: seo?.description || seo?.description || 'De We Are Public-pas. Eindeloos cultuur voor een vast bedrag per maand.'
            }
        ],
        link: [] as {}[]
    };

    if (seo?.social?.facebook) {
        if (seo.social.facebook.image?.url) {
            metaData.meta.push({
                hid: 'og:image',
                name: 'og:image',
                content: seo.social.facebook.image.url
            });
        }

        if (seo.social.facebook.title) {
            metaData.meta.push({
                hid: 'og:title',
                name: 'og:title',
                content: seo.social.facebook.title
            });
        }

        if (seo.social.facebook.description) {
            metaData.meta.push({
                hid: 'og:description',
                name: 'og:description',
                content: seo.social.facebook.description
            });
        }
    }

    if (seo?.social?.twitter?.image?.url) {
        metaData.meta.push({
            hid: 'twitter:image',
            name: 'twitter:image',
            content: seo.social.twitter.image.url
        });
    }

    metaData.meta.push({
        hid: 'robots',
        name: 'robots',
        content: seo?.advanced?.robots ? seo.advanced.robots.join(', ') : 'a;;'
    });

    if (seo?.advanced?.canonical) {
        metaData.link.push({
            rel: 'canonical',
            href: seo.advanced.canonical
        });
    }

    return metaData;
}
