import { SIGNUP_URL } from '~/constants/membership';

export const STATUS = {
    429: 'Teveel pogingen. Wacht 5 minuten en probeer het opnieuw.',
    403: 'Je hebt geen toestemming om deze actie uit te voeren.',
    500: 'Helaas is er een onbekende fout opgetreden. Probeer het opnieuw.'
};

export const LOGIN_INVALID_CREDENTIALS = 'Geen geldige inlog en/of wachtwoordcombinatie';

// TODO: correct link?
export const LOGIN_INACTIVE_USER = `Heb je al eens een lidmaatschap gehad? <a href="/${SIGNUP_URL}">Heractiveer je lidmaatschap</a>`;

export const PASSWORD_RESET_INVALID_CODE = 'Je hebt een ongeldige code ingevoerd. Probeer het opnieuw.';

export const PASSWORD_RESET_TOO_MANY_ATTEMPTS = 'Teveel pogingen. Wacht 5 minuten en probeer het opnieuw.';

export const PASSWORD_RESET_INVALID_REQUIREMENTS = 'Je wachtwoord moet minimaal 8 tekens lang zijn.';

export const PASSWORD_MISMATCH = 'De wachtwoorden komen niet overeen';

export const CHANGE_PASSWORD_INVALID_CREDENTIALS = 'Het ingevoerde wachtwoord is incorrect. Om je nieuwe wachtwoord in te kunnen stellen hebben we voor de zekerheid ook je oude wachtwoord nodig.';

export const EVENT_FULL_CAPACITY = 'Er zijn geen plaatsen (meer) voor dit programma.';

// Helper function to add some (basic) generic error messaging.
export const getDefaultErrorMessage = function(error) {
    let errorMessage = null;

    if (error?.response?.status === 403) {
        errorMessage = STATUS['403'];
    } else {
        errorMessage = STATUS['500'];
    }

    return errorMessage;
};
