import { defineStore } from 'pinia';
import type { User } from '~/@types/data';

export const JWT_COOKIE_KEY = 'wap_session';
export const JWT_COOKIE_NEEDS_REFRESH_AT_KEY = 'wap_session_refresh_at';

export const JWT_COOKIE_CONFIG = {
    maxAge: 31557600, // 1 year
    sameSite: 'strict',
    secure: true
} as const;

export const useUserStore = defineStore('user', {
    state: () => {
        return {
            user: null as null | User,
            token: null as null | undefined | string
        };
    },

    getters: {
        reservations(): number[] {
            return this.user?.reservations || [];
        },

        canMakeReservation(): boolean {
            return this.user?.canMakeReservation ? true : false;
        },

        canMakeFreePremiumReservation(): boolean {
            return this.user?.canMakeFreePremiumReservation ? true : false;
        },

        loggedIn(): boolean {
            return !!this.user;
        },

        jwtTokenExpired(): boolean{
            const jwtNeedsRefreshAtCookie = useCookie(JWT_COOKIE_NEEDS_REFRESH_AT_KEY, JWT_COOKIE_CONFIG);

            if (typeof jwtNeedsRefreshAtCookie.value === 'string') {
                return parseInt(jwtNeedsRefreshAtCookie.value, 10) < Date.now();
            }

            return false;
        },

        jwtToken(): string|null {
            refreshCookie(JWT_COOKIE_KEY);

            const jwtTokenCookie = useCookie(JWT_COOKIE_KEY, JWT_COOKIE_CONFIG);

            const token = this.token || jwtTokenCookie.value;

            return token ? `Bearer ${token}` : null;
        }
    },

    actions: {
        setUser(payload: User) {
            this.user = payload;
        },

        async fetchUser() {
            if (!this.jwtToken) {
                console.error('cannot fetch user, missing auth token');
            }

            const {
                auth
            } = useRuntimeConfig().public;

            const response = await useLaravelApi(
                auth.endpoints.user.url,
                {
                    method: 'GET'
                }
            );

            if (response) {
                this.user = response as User;
            }
        },

        async logout() {
            const {
                auth
            } = useRuntimeConfig().public;

            await useLaravelApi(auth.endpoints.logout.url, {
                method: auth.endpoints.logout.method
            });

            this.user = null;
            this.token = null;

            this.unsetJwtToken();

            useNuxtApp().runWithContext(() => {
                reloadNuxtApp({
                    path: '/'
                });
            });
        },

        async fetchRefreshToken() {
            const {
                auth
            } = useRuntimeConfig().public;

            const response = await useLaravelApi(auth.endpoints.refresh.url, {
                method: auth.endpoints.refresh.method
            });

            if (response.token) {
                this.setJwtToken(response.token as string);
            } else {
                this.unsetJwtToken();
            }
        },

        setJwtToken(token: string) {
            const jwtTokenCookie = useCookie(
                JWT_COOKIE_KEY,
                JWT_COOKIE_CONFIG
            );

            const jwtNeedsRefreshAtCookie = useCookie(
                JWT_COOKIE_NEEDS_REFRESH_AT_KEY,
                JWT_COOKIE_CONFIG
            );

            this.token = token;

            jwtNeedsRefreshAtCookie.value = (Date.now() + 3540000).toString(10);

            jwtTokenCookie.value = token;
        },

        unsetJwtToken() {
            const jwtTokenCookie = useCookie(
                JWT_COOKIE_KEY,
                JWT_COOKIE_CONFIG
            );

            this.token = null;

            jwtTokenCookie.value = null;

            refreshCookie(JWT_COOKIE_KEY);
        },

        hasReservation(id: number) {
            const reservations = this.user?.reservations || [];

            return reservations.includes(id);
        },

        isInWaitingList(id: number) {
            const waitingList = this.user?.waitingLists || [];

            return waitingList.includes(id);
        },

        hasFavorite(id: number) {
            const favorites = this.user?.savedProgrammes || [];

            return favorites.includes(id);
        }
    }
});
