<template>
    <span class="event-disciplines">
        {{ formattedLabel }}
    </span>
</template>

<script>
export default {
    props: {
        event: {
            type: Object,
            default: null
        }
    },

    computed: {
        disciplines() {
            if (!this.event || !this.event.disciplines || !this.event.disciplines.length) {
                return;
            }

            return this.event.disciplines;
        },

        formattedLabel() {
            let label = '';

            if (!this.disciplines || !this.disciplines.length) {
                return;
            }

            if (this.disciplines.length === 1) {
                return this.disciplines[0].name;
            }

            this.disciplines.forEach((discipline, index) => {
                if (index === 0) {
                    label += discipline.name;
                }

                if (index > 0) {
                    label += `, ${discipline.name}`;
                }
            });

            return label;
        }
    }
};
</script>
