<template>
    <span
        title="Je hebt een reservering voor dit programma"
        class="reserved-label"
    >
        <BaseIcon icon="check" />
    </span>
</template>

<style src="./ReservedLabel.less" lang="less"></style>
