<template>
    <BaseForm
        class="account-details"
        @submit="submitUserDetails"
    >
        <template #title>
            Vul je naam en adresgegevens in
        </template>

        <template #content>
            <FieldValidator
                v-slot="{ field, meta, errors, handleReset }"
                name="firstName"
            >
                <BaseFormField
                    :required="true"
                    :errors="errors"
                >
                    <template #label>Voornaam</template>
                    <BaseInput
                        v-bind="field"
                        type="text"
                        :validator="meta"
                        @clear="handleReset"
                    />
                </BaseFormField>
            </FieldValidator>

            <FieldValidator
                v-slot="{ field, meta, errors, handleReset }"
                name="lastName"
            >
                <BaseFormField
                    :required="true"
                    :errors="errors"
                >
                    <template #label>Achternaam</template>
                    <BaseInput
                        v-bind="field"
                        type="text"
                        :validator="meta"
                        @clear="handleReset"
                    />
                </BaseFormField>
            </FieldValidator>

            <FieldValidator
                v-slot="{ field, meta, errors, handleReset }"
                name="birthdate"
            >
                <BaseFormField
                    :required="true"
                    :errors="errors"
                >
                    <template #label>Geboortedatum</template>

                    <BaseInput
                        v-bind="field"
                        type="date"
                        :validator="meta"
                        @clear="handleReset"
                    />
                </BaseFormField>
            </FieldValidator>

            <FormFieldColumns
                data-columns-mobile="2"
                data-columns-desktop="2"
            >
                <FieldValidator
                    v-slot="{ field, meta, errors, handleReset }"
                    name="street"
                >
                    <BaseFormField
                        :required="true"
                        :errors="errors"
                    >
                        <template #label>Straatnaam</template>
                        <BaseInput
                            v-bind="field"
                            type="text"
                            :validator="meta"
                            @clear="handleReset"
                        />
                    </BaseFormField>
                </FieldValidator>

                <FieldValidator
                    v-slot="{ field, meta, errors, handleReset }"
                    name="streetNumber"
                >
                    <BaseFormField
                        :required="true"
                        :errors="errors"
                    >
                        <template #label>Huisnummer</template>
                        <BaseInput
                            v-bind="field"
                            type="text"
                            :validator="meta"
                            @clear="handleReset"
                        />
                    </BaseFormField>
                </FieldValidator>
            </FormFieldColumns>

            <FormFieldColumns
                data-columns-mobile="2"
                data-columns-desktop="2"
            >
                <FieldValidator
                    v-slot="{ field, meta, errors, handleReset }"
                    name="zipCode"
                >
                    <BaseFormField
                        :required="true"
                        :errors="errors"
                    >
                        <template #label>Postcode</template>
                        <BaseInput
                            v-bind="field"
                            type="text"
                            :validator="meta"
                            @clear="handleReset"
                        />
                    </BaseFormField>
                </FieldValidator>

                <FieldValidator
                    v-slot="{ field, meta, errors, handleReset }"
                    name="city"
                >
                    <BaseFormField
                        :required="true"
                        :errors="errors"
                    >
                        <template #label>Plaats</template>
                        <BaseInput
                            v-bind="field"
                            type="text"
                            :validator="meta"
                            @clear="handleReset"
                        />
                    </BaseFormField>
                </FieldValidator>
            </FormFieldColumns>

            <BaseFormField
                v-if="showPhysicalPassField"
                class="dn-form-field--confirm"
            >
                <template #label>
                    We sturen in principe geen fysieke passen meer op.
                    Je online pas werkt namelijk precies hetzelfde.
                    Zo sparen we plastic, papier en vervoer.
                    Mocht je toch een fysieke pas willen, dan kun je dat aangeven.
                </template>

                <BaseCheckbox
                    v-model="physicalPass"
                    :value="true"
                >
                    Ja, ik wil toch een fysieke pas
                </BaseCheckbox>
            </BaseFormField>
        </template>

        <template #actions>
            <BaseButton
                type="submit"
                class="form__submit button--secondary form__button--max-width"
            >
                Volgende stap
            </BaseButton>
        </template>
    </BaseForm>
</template>

<script setup lang="ts">
import { useForm, Field as FieldValidator } from 'vee-validate';

withDefaults(
    defineProps<{
        showPhysicalPassField: boolean
    }>(),
    {
        showPhysicalPassField: true
    }
);

const emit = defineEmits(['submit']);

interface UserDetailsForm {
    firstName: string;
    lastName: string;
    birthdate: string;
    zipCode: string;
    streetNumber: string;
    street: string;
    city: string;
    physicalPass: boolean;
}

const { handleSubmit } = useForm<UserDetailsForm>({
    validationSchema: {
        firstName: 'required',
        lastName: 'required',
        birthdate: 'required',
        zipCode: 'required|zipCode',
        streetNumber: 'required',
        street: 'required',
        city: 'required',
    }
});

const physicalPass = ref(false);

const submitUserDetails = handleSubmit((formData) => {
    emit('submit', {
        first_name: formData.firstName,
        last_name: formData.lastName,
        birthdate: formData.birthdate,
        zip: formData.zipCode,
        street_number: formData.streetNumber,
        street: formData.street,
        city: formData.city,
        physical_pass: physicalPass.value
    });
});
</script>

<style lang="less" src="./FormUserDetails.less" />
