<template>
    <div
        class="select"
        :class="{
            'select--has-pre': !!$slots.pre
        }"
    >
        <div
            v-if="!!$slots.pre"
            class="select__pre"
        >
            <slot name="pre" />
        </div>

        <select
            v-model="model"
            class="select__control"
            @input="handleInputEvent"
            @change="handleChangeEvent"
            @blur="handleBlurEvent"
        >
            <option
                v-if="placeholder"
                value=""
                disabled
                :selected="!model"
            >
                {{ placeholder }}
            </option>

            <slot>
                <template v-if="options">
                    <option
                        v-for="option in options"
                        :key="option.value"
                        :selected="option.value.toString() === model"
                        :value="option.value"
                    >
                        {{ option.label }}
                    </option>
                </template>
            </slot>
        </select>

        <Transition name="fade">
            <button
                v-if="model && clearable"
                class="select__clear"
                @click="clear"
            >
                <BaseIcon icon="cross" />
            </button>
        </Transition>

        <button
            v-if="!!$slots.after && ((!model && clearable) || !clearable)"
            class="select__after"
        >
            <slot name="after" />
        </button>
    </div>
</template>

<script setup lang="ts">
import type { FilterOption } from '~/@types/data';

withDefaults(
    defineProps<{
        options: FilterOption[] | null;
        placeholder?: string;
        clearable?: boolean;
    }>(),
    {
        clearable: true,
        placeholder: undefined
    }
);

const emit = defineEmits(['input', 'change', 'blur']);

const model = defineModel<string|number|null>();

const clear = () => {
    model.value = null;

    emit('input', null);
};

const handleInputEvent = (event: Event) => {
    const target = event.target as HTMLSelectElement;

    emit('input', target.value);
};

const handleChangeEvent = (event: Event) => {
    const target = event.target as HTMLSelectElement;

    emit('input', target.value);
};

const handleBlurEvent = (event: Event) => {
    const target = event.target as HTMLSelectElement;

    emit('input', target.value);
};
</script>

<style lang="less" src="./BaseSelect.less" />
