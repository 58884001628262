import type { Artist_Category, City_Category, Curator_Category, Discipline_Category, EventBundleFragment, EventContent_Category, Partner_Category, ProgramLine_Category, Purview_Category, Region_Category, Tag_Category, Venue_Category } from '~/@types/craft-schema';
import { ref, computed } from 'vue';

type RelationFilterObj = {
    laravelModelId: number;
}

type EventBundleRelated = {
    __typename: '[custom]event_bundle_related';
    limit: number;
    discipline?: RelationFilterObj[];
    city: RelationFilterObj[];
    startDate?: string;
    skipEvent: number;
}

type EventBundleUserSaved = {
    __typename: '[custom]event_bunlde_user_saved';
    limit?: number;
    preset: string;
}

export type QueryProp = EventBundleFragment
    | EventBundleRelated
    | EventBundleUserSaved;

export interface ComponentProps {
    query: QueryProp;
    lazy?: boolean;
}

export type FilterObjectType = Record<string, any>;

const DEFAULT_QUERY_LIMIT = 10;

/**
 * Only use in nuxt context 
 *
 */

export const useEventBundle = (props: ComponentProps) => {
    const route = useRoute();

    const totalResults = ref<number|null>(null);
    const currentPageNumber = ref(1);
    const lastPageNumber = ref<number|null>(null);
    const notFound = ref(false);

    const addRelationFilter = (
        relationArray: Array<
            Artist_Category
            | City_Category
            | Region_Category
            | Curator_Category
            | Discipline_Category
            | EventContent_Category
            | Partner_Category
            | ProgramLine_Category
            | Purview_Category
            | Tag_Category
            | Venue_Category
        >,
        filterObj: FilterObjectType,
        filterObjKey: keyof FilterObjectType
    ) => {
        const filterValues: string[] = [];

        if (relationArray.length > 0) {
            relationArray.forEach((related) => {
                if (related && related.laravelModelId) {
                    filterValues.push(`${related.laravelModelId}`);
                }
            });
        }

        if (filterValues.length > 0) {
            filterObj[filterObjKey] = filterValues;
        }
    };

    // Individual filter properties
    const applyPresetFilter = (preset: string | null, filterObject: FilterObjectType) => {
            if (preset) {
                filterObject.preset = preset;
            }
        },

        applyArtistFilter = (artist: Artist_Category[], filterObject: FilterObjectType) => {
            addRelationFilter(artist, filterObject, 'artist');
        },

        applyCityFilter = (city: City_Category[]|null, filterObject: FilterObjectType) => {
            if (city) {
                addRelationFilter(city, filterObject, 'city');
            }

            if (!cityFilterState.value || filterObject?.city?.includes(cityFilterState.value)) {
                return;
            }
        
            if (cityFilterState.value) {
                if (Array.isArray(filterObject.city)) {
                    filterObject.city.push(cityFilterState.value);
                } else {
                    filterObject.city = [cityFilterState.value];
                }
            }
        },

        applyRegionFilter = (filterObject: FilterObjectType) => {
            if (
                !regionFilterState.value ||
                (
                    Array.isArray(filterObject.region) &&
                    filterObject.region.includes(regionFilterState.value)
                )
            ) {
                return;
            } else if (regionFilterState.value && !filterObject.region) {
                filterObject.region = [regionFilterState.value];
            }
        },

        applyCuratorFilter = (curator: Curator_Category[], filterObject: FilterObjectType) => {
            addRelationFilter(curator, filterObject, 'curator');
        },

        applyDisciplineFilter = (discipline: Discipline_Category[], filterObject: FilterObjectType) => {
            addRelationFilter(discipline, filterObject, 'discipline');
        },

        applyEventContentFilter = (eventContent: EventContent_Category[], filterObject: FilterObjectType) => {
            addRelationFilter(eventContent, filterObject, 'eventContent');
        },

        applyPartnerFilter = (partner: Partner_Category[], filterObject: FilterObjectType) => {
            addRelationFilter(partner, filterObject, 'partner');
        },
        
        applyProgramLineFilter = (programLine: ProgramLine_Category[], filterObject: FilterObjectType) => {
            addRelationFilter(programLine, filterObject, 'programLine');
        },

        applyPurviewFilter = (purview: Purview_Category[], filterObject: FilterObjectType) => {
            addRelationFilter(purview, filterObject, 'purview');
        },

        applyTagFilter = (tag: Tag_Category[], filterObject: FilterObjectType) => {
            addRelationFilter(tag, filterObject, 'tag');
        },

        applyVenueFilter = (venue: Venue_Category[], filterObject: FilterObjectType) => {
            addRelationFilter(venue, filterObject, 'venue');
        },

        applyStartDateFilter = (startDate: string | null, filterObject: FilterObjectType) => {
            if (startDate) {
                filterObject.startDate = startDate;
            }

            if (!startDateFilterState.value) {
                return;
            }
        
            filterObject.startDate = startDateFilterState.value as string;
        },

        applyExcludeFilter = (skipEvent: number|null, filterObject: FilterObjectType) => {
            if (skipEvent) {
                filterObject.skipEvent = skipEvent;
            }
        };

    const startDateFilterState = computed(() => {
            if (!route.query.startDate) {
                return false;
            }

            return route.query.startDate;
        }),

        cityFilterState = computed(() => {
            if (!route.query.city) {
                return false;
            }

            return parseInt(route.query.city as string, 10);
        }),

        regionFilterState = computed(() => {
            if (!route.query.region) {
                return false;
            }

            return parseInt(route.query.region as string, 10);
        });

    const filterQuery = computed<Record<string, string>>((): Record<string, string> => {
        const filters: Record<string, string> = {};

        if (props.query.__typename === 'eventBundle_BlockType') {
            applyArtistFilter(props.query.artist as Artist_Category[], filters);
            applyCityFilter(props.query.city as City_Category[], filters);
            applyCuratorFilter(props.query.curator as Curator_Category[], filters);
            applyDisciplineFilter(props.query.discipline as Discipline_Category[], filters);
            applyEventContentFilter(props.query.eventContent as EventContent_Category[], filters);
            applyPartnerFilter(props.query.partner as Partner_Category[], filters);
            applyProgramLineFilter(props.query.programLine as ProgramLine_Category[], filters);
            applyPurviewFilter(props.query.purview as Purview_Category[], filters);
            applyTagFilter(props.query.tag as Tag_Category[], filters);
            applyVenueFilter(props.query.venue as Venue_Category[], filters);
            applyVenueFilter(props.query.venue as Venue_Category[], filters);

            applyStartDateFilter(null, filters);
            applyRegionFilter(filters);
            applyPresetFilter(props.query.preset || null, filters);
        }

        if (props.query.__typename === '[custom]event_bundle_related') {
            applyCityFilter(props.query.city as City_Category[], filters);
            applyRegionFilter(filters);
            applyExcludeFilter(props.query.skipEvent || null, filters);
            applyStartDateFilter(props.query.startDate || null, filters);

            if (props.query.discipline) {
                applyDisciplineFilter(props.query.discipline as Discipline_Category[], filters);
            }
        }

        if (props.query.__typename === '[custom]event_bunlde_user_saved') {
            applyCityFilter(null, filters);
            applyRegionFilter(filters);
            applyStartDateFilter(null, filters);
            applyPresetFilter(props.query.preset || null, filters);
        }

        return {
            'limit': props.query?.limit ? `${props.query?.limit}` : DEFAULT_QUERY_LIMIT.toString(10),
            ...filters
        };
    });

    return {
        props,
        totalResults,
        currentPageNumber,
        lastPageNumber,
        notFound,
        filterQuery,
        cityFilterState
    };
};