<template>
    <BaseForm
        class="order-gift-step-three"
        @submit="submit"
    >
        <template #title>
            Betalen
        </template>

        <template #content>
            <div class="payment-info">
                <slot name="info" />
            </div>

            <p class="payment-info--static">
                Je kunt betalen met iDeal of creditcard, hiervoor wordt je doorgestuurd naar Mollie.
            </p>

            <div class="terms-and-conditions">
                <div class="terms-and-conditions__field-wrapper">
                    <BaseCheckbox v-model="formData.accepted">
                        <slot name="termsAndConditionsLabel" />
                    </BaseCheckbox>
                </div>

                <p
                    v-if="formErrors"
                    class="terms-and-conditions__field-error"
                >
                    {{ formErrors }}
                </p>
            </div>
        </template>

        <template #actions>
            <div class="form__actions__button-wrapper">
                <BaseButton
                    type="button"
                    @click.prevent="emit('back')"
                >
                    Vorige stap
                </BaseButton>

                <BaseButton class="button--secondary">
                    Betaal
                </BaseButton>
            </div>

            <p>
                Problemen met betalen?
                Neem gerust contact met ons op.
            </p>
        </template>
    </BaseForm>
</template>

<script setup lang="ts">
const emit = defineEmits(['submit', 'back']);

const formErrors = ref<string|null>(null);
const formData = ref({
    accepted: false
});

const submit = () => {
    formErrors.value = null;

    if (formData.value.accepted === false) {
        formErrors.value = 'Om de betaling te voltooien moet u instemmen met de voorwaarden';
    } else {
        emit('submit', formData.value);
    }
};
</script>

<style lang="less" src="./FormOrderGiftStepThree.less">
</style>
