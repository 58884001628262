<template>
    <BaseSection
        width="content-sm"
        :color-theme="colorTheme"
    >
        <BaseTestimonial
            :content="textPlain"
            :image="testimonialImage"
            :author="author"
            :title="title"
        />
    </BaseSection>
</template>

<script setup lang="ts">
import type { FlexibleQuoteFragment } from '~/@types/craft-schema';
import type { Media_Asset_Extended } from '~/@types/craft-schema.extended';
const props = defineProps<FlexibleQuoteFragment>();

const testimonialImage = props.image[0] ? props.image[0] as Media_Asset_Extended : null;
</script>
