import { ofetch, type FetchOptions } from 'ofetch';
import { JWT_COOKIE_KEY, useUserStore } from '~/store/user';

export default async function(requestPath: string, request: FetchOptions = {}): Promise<any> {
    const { laravelApiToken, laravelApiBaseURL } = useRuntimeConfig().public;

    const userStore = useUserStore();

    const requestFetchOptions = {
        ...request,

        headers: {
            'x-api-key': laravelApiToken as string,
            'Accept': 'application/json',
        } as Record<string, string>
    };

    refreshCookie(JWT_COOKIE_KEY);

    if (userStore.jwtTokenExpired) {
        await userStore.fetchRefreshToken();
    }

    refreshCookie(JWT_COOKIE_KEY);

    const jwtToken = userStore.jwtToken;

    if (jwtToken) {
        requestFetchOptions.headers.Authorization = jwtToken;
    }

    return ofetch(laravelApiBaseURL + requestPath, requestFetchOptions);
}
