<template>
    <BaseSlider
        v-if="events && events.length > 0"
        v-inview
        :items="events"
        class="event-slider"
        :class="{
            'event-slider--large-cards': sliderCardSize === 'large'
        }"
    >
        <template #slide="{ item }">
            <EventCard
                :event="item"
                :class="{
                    'event-card--large': sliderCardSize === 'large'
                }"
                drag-to-scroll="ignore"
                draggable="false"
            />
        </template>
    </BaseSlider>
</template>

<script setup lang="ts">
import type { EventDate } from '~/@types/data';

interface Props {
    events: EventDate[];
    cardSize?: string | null;
}

const props = withDefaults(defineProps<Props>(), {
    cardSize: 'default'
});

const sliderCardSize = props.cardSize && ['default', 'large'].includes(props.cardSize)
    ? props.cardSize
    : 'default';
</script>

<style lang="less" src="./EventSlider.less"></style>
