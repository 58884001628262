<template>
    <!-- eslint-disable vue/no-v-html -->
    <div
        v-if="content"
        class="article"
        v-html="content"
    />
    <!-- eslint-enable vue/no-v-html -->

    <div
        v-else
        class="article"
    >
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        content: {
            type: String,
            default: ''
        }
    }
};
</script>

<style lang="less" src="./RichText.less"></style>
