function inViewUpdate(entries) {
    for (const entry of entries) {
        entry.target.classList.toggle('inview--viewing', entry.isIntersecting);

        if (entry.isIntersecting) {
            entry.target.dispatchEvent(new Event('enter'));
            entry.target.classList.add('inview--viewed');
        } else {
            entry.target.dispatchEvent(new Event('leave'));
        }
    }
}

export default defineNuxtPlugin((nuxtApp) => {
    const inViewOptions = {
        threshold: [0.5]
    };

    nuxtApp.vueApp.directive('inview', {
        created() {
            // see below for details on arguments
        },

        mounted(el) {
            const inViewObserver = new IntersectionObserver(inViewUpdate, inViewOptions);

            el.classList.add('inview--observing');

            inViewObserver.observe(el);
        },

        unmounted(el) {
            const inViewObserver = new IntersectionObserver(inViewUpdate, inViewOptions);

            if (el instanceof Element) {
                el.classList?.remove('inview--observing');

                inViewObserver.unobserve(el);
            }
        }
    });
});
