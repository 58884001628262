<template>
    <div class="event-grid">
        <ul class="event-grid__content list--clean">
            <li
                v-for="event in events"
                :key="event.id"
            >
                <EventCard
                    appearance="box"
                    :event="event"
                />
            </li>
        </ul>

        <div class="event-grid__bottom">
            <slot name="loadMore" />
            <slot name="noResults" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        events: {
            type: Array,
            default() {
                return [];
            }
        }
    },
};
</script>

<style src="./EventGrid.less" lang="less"></style>
